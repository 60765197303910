import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
const browserLanguage = navigator.language || navigator.languages[0];
i18n
    // 检测用户当前使用的语言
    // 文档: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // 注入 react-i18next 实例
    .use(initReactI18next)
    // 初始化 i18next
    // 配置参数的文档: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'zh-CN',
        lng: browserLanguage,
        interpolation: {
            escapeValue: true,
        },
        resources: {
            "zh-CN": {
                translation: {
                    app: "无双 VPN",
                    zhCN: "简体中文",
                    zhTW: "中文繁体",
                    id: "印尼语",
                    en: "英语",
                    index: "首页",
                    pp: "套餐",
                    download: "下载",
                    contact: "联系我们",
                    privacy: "隐私政策",
                    userterm: "用户条款",
                    login: "登录",
                    name_error: "名字错误",
                    start_exit: "开始退出",
                    exiting: "正在退出",
                    exited: "退出完成",
                    logout: "退出登录",
                    app_download: "应用下载",
                    banner_h1: "数据加密，保护隐私保护您的上网安全",
                    banner_p1: "无限流量, 高清秒开无日志, 防跟踪",
                    download_tips: "开发小哥正在加班开发中,你可以尝试其他平台",
                    other_platform: "其他平台",
                    select_install_p: "请选择你要下载的安装方式",
                    get_download_link: "正在获取下载链接",
                    alipay: "支付宝",
                    to_pay: "前往支付",
                    unselectgoodtype: "未选择商品类型",
                    params_error_tips: "传递参数错误，请检查！",
                    select_goods: "选择套餐",
                    trade_goods_tips: "无双 会员套餐，购买后可直接在 app 上使用，如果支付过程遇到问题，请联系我们",
                    original_price: "原价",
                    select_pay_type: "选择支付方式",
                    tips_not_input_email: "未输入邮箱",
                    tips_email_format_error: "邮箱格式错误",
                    tips_not_input_content: "未输入内容",
                    tips_send_success: "发送成功",
                    contact_us_content: "如果你对产品有任何疑问，或者想要对我们提出任何意见或建议，请联系我们",
                    please_input_email: "请输入您的邮箱，我们会将结果回复到此邮箱",
                    please_input_content: "请输入您想要发送给我们的内容",
                    contact_content: "请输入内容",
                    your_email: "您的邮箱",
                    send: "发送",
                    send_verify_code: "发送验证码",
                    miss_email: "缺少邮箱",
                    resend: "s后重新发送",
                    pzinputEmail: "请输入邮箱账号",
                    pzinputcode: "请输入验证码",
                    loginerror: "账号或密码不正确",
                    not_regist: "还未注册?",
                    regist_success: "注册成功",
                    not_input_account: "尚未输入账号",
                    send_code_error: "发送验证码错误",
                    regist: "注册",
                    go_login: "去登录",
                    privacy_content: {
                        p1: "{{name}}尊重并全力保护用户隐私。我们希望您了解我们收集的信息，不收集的信息以及我们如何收集、使用和存储信息。我们不收集您的活动日志，包括不记录浏览历史记录、流量数据、数据内容或 DNS 查询。我们也从不存储连接日志，这意味着您的 IP 地址、连接时间或会话持续时间都不会记录日志",
                        h2: "同意",
                        p2: "我们非常注重用户隐私，因此制定隐私政策。请您仔细阅读这些隐私政策的内容，以协助本平台为您提供满意的服务。本政策内容涵盖本平台如何经由使用本平台而收集所得的个人资料。使用本网站服务，即表示您已同意接受本平台的隐私政策，并同意本平台按照以下政策收集个人资料及使用方法。",
                        h3: "手机号 & 邮箱",
                        p3: "当您注册本平台时，您可以选择填写手机号或者邮箱，但是两者不是必须的，不填写也不会影响账号注册。我们非常尊重用户个人信息，不会为满足第三方营销目的而向其出售或出租您的任何信息。",
                        h4: "年龄限制",
                        p4: "该服务适用于18岁以上的成年人。如果您认为您的孩子已经向我们提供了信息，请立即告知我们。",
                        h5: "信息安全",
                        p5: "您也有责任保护及妥善存放个人资料，避免通过任何途径（包括电话以及电邮）向其他人分享或公开您在本平台的登录密码。必要时，我们会使用强大的加密算法来安全地保护所有公钥和私钥以及密码。采取了适当的安全措施来保护此信息，防止未经授权的访问，未经授权的更改，破坏或泄露数据。"
                    },
                    userterm_content: {
                        p1: "{{name}} 有权在任何时间及不需事先通知的情况下修改、取消、增加、替代任何在此列明的条款，而此条款均对本网站的使用者及注册会员有效力及约束力。因此，您应定期浏览本页。如在本条款作出修订后仍继续使用本网站，即被视为接受这些修订。\n当您使用 {{name}} 或注册成为本平台会员的同时，您将会被视作接受本条款之所有规则约束。请您在使用本平台前仔细阅读所有有关条款。",
                        p12: "本条款最新修订日期为 2023 年 5 月 28 日",
                        h2: "服务",
                        p2: " {{name}}  服务是暂时性的数字网络通信服务，可增强 Internet 安全性和隐私性。根据您的个人要求和您的全权酌情决定权，我们会自动提供不受监管和不受监控的数据管道-虚拟专用网（VPN）连接-供您专有使用，您对使用{{name}}  服务负全责。{{name}}  对用户采取的行动不承担任何责任，包括对已执行或未执行的损害承担刑事责任和民事责任。\n这些条款的其他规定进一步限制了{{name}}  的责任。\n我们授予您使用 {{name}}  服务的许可，但要遵守这些条款中规定的限制。您使{{name}}  服务的风险自负。{{name}}  服务可以随时修改，更新，中断或暂停，恕不另行通知或承担责任。对于由此造成的对您的任何伤害或其他不利后果，我们不承担任何责任。{{name}} ，其所有者，员工，代理商和其他与{{name}}  服务相关的人员，对于因使用或向使用其注册的任何账户而产生或引起的任何已执行或未执行的任何形式的损害，概不负责。\n{{name}}  保证99.9％的服务正常运行时间。在任何情况下，{{name}}  对超出客户向{{name}}  支付的任何损失，金钱或非金钱损失概不负责，并且不包括律师费或法院费用，与任何法律或其他成文法则无关。",
                        h3: "知识产权",
                        p3: "根据这些条款和条件，我们授予您有限的、非排他的、个人的、不可转让的、不可再许可的许可，以：<br /><ul>  <li style={{ 'margin': '8px 0 0 12px'    }}>下载和使用{{name}}  软件的副本；</li>  <li style={{ 'margin': '8px 0 0 12px' }}> 使用{{name}}  服务，包括但不限于在{{name}}  软件或我们的网站上或通过我们的网站提供的产品和服务。</li></ul>&nbsp;&nbsp;&nbsp; 根据{{name}}  服务，本协议未授予您任何其他权利或许可。本文提供的许可在终止之前一直有效。如果您不遵守这些条款，该许可将自动终止。{{name}}  服务（包括但不限于{{name}}  软件，移动应用程序和所有其他项目）由{{name}}  拥有并拥有版权，并且在全球范围内受到保护。我们保留对{{name}}  服务及其任何部分的权利，所有权和利益，包括但不限于所有版权，商标，服务标记，商业秘密和其他知识产权。您不得采取任何行动来危害，限制或以任何方式干扰我们对{{name}}  服务的所有权和权利。除非另有说明，否则保留所有权利。",
                        h4: "禁止事项",
                        p4: `<p>
                        您对{{name}}  服务的访问和使用受这些条款以及所有适用法律和法规的约束。对于任何违反任何适用法律或本条款的用户，我们保留随时酌情决定随时终止或阻止其访问{{name}}  服务的权利，无论是否另行通知。
                        <br />
                        在使用{{name}}  服务时，您自己同意不协助、鼓励或允许其他人使用{{name}}  服务：
                        <br />
                        1) 用于不正当的、禁止的、违法、犯罪或者欺诈活动，包括但不限于端口扫描，发送垃圾邮件，发送选择接收的电子邮件，扫描开放中继或开放代理，发送未经请求的电子邮件或发送的任何版本或类型的电子邮件即使通过第三方服务器路由电子邮件，任何弹出窗口启动，使用被盗信用卡，信用卡欺诈，金融欺诈，加密货币欺诈，伪装，敲诈，勒索，绑架，强奸，谋杀，出售的信用卡被盗，出售被盗商品，提供或出售违禁，军事和双重用途商品，提供或出售受控物质，身份盗窃，黑客入侵，伪造，网络钓鱼，任何形式或规模的刮取，数字盗版，知识产权侵权和其他类似活动；
                        <br />
                        2) 通过{{name}}  服务以任何方式或形式攻击，干扰，获得未经授权的访问，拒绝任何其他网络，计算机或节点的服务；
                        <br />
                        3) 以任何方式剥削儿童，包括音频，视频，摄影，数字内容等；
                        <br />
                        4) 以任何方式上传或分发包含病毒，蠕虫，特洛伊木马，损坏的文件或任何其他可能损害他人计算机操作的类似软件或程序的文件；
                        <br />
                        5) 干扰或试图干扰{{name}}  服务的正常工作，与{{name}}  服务有关的任何交易或我们进行的任何其他活动，破坏我们的网站或与{{name}}  服务相连的任何网络或绕过任何措施我们可能会用来阻止或限制对{{name}}  服务的访问；
                        <br />
                        6) 以任何未经授权的方式利用{{name}}  服务，包括但不限于侵入或增加网络容量；
                        <br />
                        7) 未经我们事先书面许可，出于任何目的使用任何机器人，蜘蛛，刮板或其他自动方式访问我们的网站或{{name}}  服务；
                        <br />
                        8) 收集或收集有关{{name}}  服务其他用户的个人信息；
                        <br />
                        9) 未经合法利益或同意，或违反任何适用法律而收集或收集个人数据；
                        <br />
                        10) 采取任何可能会对我们的基础架构施加不合理或不成比例的巨大负载的措施；
                        <br />
                        11) 与任何不合理数量的人共享任何数据或其他内容，包括但不限于向大量接收者发送爆炸性通信或与您不认识或不认识您的人共享内容；
                        <br />
                        12) 代表您是任何{{name}}  服务（包括其任何功能）的代表或代理商；
                        <br />
                        13) 将{{name}}  服务或其任何部分合并到任何其他程序或产品中；
                        <br />
                        14) 以任何形式或通过任何方式复制或复制{{name}}  服务的任何部分；
                        <br />
                        15) 根据{{name}}  服务修改，翻译，反向工程，反编译，反汇编或创建任何衍生作品，包括其任何文件或文档，或其任何部分，或确定或尝试确定任何源代码，算法，方法或{{name}}  应用程序或其任何部分中包含的技术；
                        <br />
                        16) 违反一般的道德或道德规范，良好风俗和公平行为规范；
                        <br />
                        17) 侵犯任何第三方的权利，包括任何违反保密性，个人数据，版权或任何其他知识产权或所有权的权利；
                        <br />
                        18) 威胁，跟踪，伤害或骚扰他人，或助长偏执或歧视；
                        <br />
                        19) 试图操纵{{name}}  名称，服务或产品；
                        <br />
                        20) 向未成年人征求或收集个人信息或与未成年人交流；
                        <br />
                        21) 试图通过黑客攻击，密码挖掘，暴力破解或任何其他方式未经授权访问{{name}}  服务，用户帐户，连接到{{name}}  服务的计算机系统或网络；
                        <br />
                        22) 将{{name}}  服务用于任何军事目的，包括网络战，武器开发，设计，制造或生产导弹，核，化学或生物武器；
                        <br />
                        23) 以其他方式侵犯或规避这些条款。
                        <br />
                        除此之外，长时间占用宽带进行下载导致服务器网络异常，影响到其他用户正常使用的，{{name}}  会采取措施限制滥用 IP 的网速。
                        <br />
                        为了您的账号安全，非定制账号仅限个人使用，请勿将账号分享给他人，分享给他人造成的损失将由您自己承担。
                        <br />
                        我们保留自行决定拒绝服务，暂停帐户或限制访问{{name}}  服务的权利。{{name}}  可以立即实施此类暂停或访问限制，而无需任何指示，通知或退款。我们可能会暂停您的帐户以进行澄清，调查，或要求您解释您的操作并提供其他信息。如果您的帐户已被暂停，则必须与我们联系以获取更多信息。在我们永久终止用户帐户之前，我们可能会在合理的时间内暂停您的用户帐户。
                        <br />
                        我们没有义务对您执行条款。我们鼓励您让我们知道任何{{name}}  用户违反这些条款的情况；但是，如果发生此类违规行为，我们可以自行决定采取适当的措施。
                        <br />
                        免责声明
                        <br />
                        我们将努力防止网站和服务受到干扰。但是，这些内容是按“原样”和“可用”的基础提供的，我们不保证（明示或暗示）通过本网站或服务提供的任何材料或信息的准确性，或它们对以下内容的适用性：任何特定目的。我们明确否认任何形式的担保，无论是明示的还是暗示的，包括但不限于适销性或针对特定目的的适用性或非侵权的担保。我们不保证本服务将满足您的要求，也不保证其不间断，及时，安全或无错误，也不对任何缺陷进行纠正。您承认自己承担风险和酌情权访问网站和服务。
                        <br />
                       {{name}}  保留调查我们认为违反这些条款的问题的权利。我们可能会（但无义务）在不另行通知的情况下，自行决定，以任何方式删除，阻止，过滤或限制我们认为是实际或潜在违反本条款所规定限制的任何材料或信息，以及任何其他可能使{{name}}  或我们的客户承担责任的活动。{{name}}  对我们未能防止此类材料或信息通过“服务”和/或进入您的计算设备的任何故障不承担任何责任。
                      </p>`,
                        h5: "适用法律",
                        p5: "本协议应受新加坡法律的管辖并根据其解释，但其法律冲突规则除外。",
                        h6: "服务条款的变更",
                        p6: "根据适用的法律和原则，我们可能会不时更改条款服务条款，恕不另行通知。您继续使用本网站或服务即表示您接受我们的隐私政策。"
                    }
                }
            },
            "zh-TW": {
                translation: {
                    app: "無雙 VPN",
                    zhCN: "簡體中文",
                    zhTW: "中文繁體",
                    en: "英語",
                    id: "印尼語",
                    index: "首頁",
                    pp: "套餐",
                    download: "下載",
                    contact: "聯絡我們",
                    privacy: "隱私權政策",
                    userterm: "使用者條款",
                    login: "登入",
                    name_error: "名字錯誤",
                    start_exit: "開始退出",
                    exiting: "正在退出",
                    exited: "退出完成",
                    logout: "登出登入",
                    app_download: "應用程式下載",
                    banner_h1: "資料加密，保護隱私保護您的上網安全",
                    banner_p1: "無限流量, 高清秒開無日誌, 防追蹤",
                    download_tips: "開發小哥正在加班開發,你可以嘗試其他平台",
                    other_platform: "其他平台",
                    select_install_p: "請選擇你要下載的安裝方式",
                    get_download_link: "正在取得下載連結",
                    alipay: "支付寶",
                    to_pay: "前往付款",
                    unselectgoodtype: "未選擇商品類型",
                    params_error_tips: "傳遞參數錯誤，請檢查！",
                    select_goods: "選擇套餐",
                    trade_goods_tips: "無雙 會員套餐，購買後可直接在 app 上使用，如果支付過程遇到問題，請聯繫我們",
                    original_price: "原價",
                    select_pay_type: "選擇付款方式",
                    tips_not_input_email: "未輸入信箱",
                    tips_email_format_error: "郵件信箱格式錯誤",
                    tips_not_input_content: "未輸入內容",
                    tips_send_success: "發送成功",
                    contact_us_content: "如果你對產品有任何疑問，或想要對我們提出任何意見或建議，請聯絡我們",
                    please_input_email: "請輸入您的信箱，我們會將結果回覆到此信箱",
                    please_input_content: "請輸入您想要傳送給我們的內容",
                    contact_content: "請輸入內容",
                    your_email: "您的信箱",
                    發送: "發送",
                    send_verify_code: "發送驗證碼",
                    miss_email: "缺少信箱",
                    resend: "s後重新發送",
                    pzinputEmail: "請輸入信箱帳號",
                    pzinputcode: "請輸入驗證碼",
                    loginerror: "帳號或密碼不正確",
                    not_regist: "還未註冊?",
                    regist_success: "註冊成功",
                    not_input_account: "尚未輸入帳號",
                    send_code_error: "發送驗證碼錯誤",
                    regist: "註冊",
                    go_login: "去登入",
                    privacy_content: {
                        p1: "{{name}}尊重並全力保護使用者隱私。我們希望您了解我們收集的信息，不收集的資訊以及我們如何收集、使用和儲存資訊。我們不收集您的活動日誌，包括不記錄瀏覽 歷史記錄、流量資料、資料內容或DNS 查詢。我們也從不儲存連線日誌，這表示您的IP 位址、連線時間或會話持續時間都不會記錄日誌",
                        h2: "同意",
                        p2: "我們非常注重使用者隱私，因此制定隱私權政策。請您仔細閱讀這些隱私權政策的內容，以協助本平台為您提供滿意的服務。本政策內容涵蓋本平台如何經由使用本平台而收集所得的 個人資料。使用本網站服務，即表示您已同意接受本平台的隱私權政策，並同意本平台依照下列政策收集個人資料及使用方法。",
                        h3: "手機號碼 & 信箱",
                        p3: "當您註冊本平台時，您可以選擇填寫手機號碼或郵箱，但是兩者不是必須的，不填寫也不會影響帳號註冊。我們非常尊重用戶個人信息，不會為滿足第三方營銷目的 而向其出售或出租您的任何資訊。",
                        h4: "年齡限制",
                        p4: "該服務適用於18歲以上的成年人。如果您認為您的孩子已經向我們提供了信息，請立即告知我們。",
                        h5: "資訊安全",
                        p5: "您也有責任保護及妥善存放個人資料，避免透過任何途徑（包括電話以及電郵）向其他人分享或公開您在本平台的登入密碼。必要時，我們會使用強大的加密演算法來安全地 保護所有公鑰和私鑰以及密碼。採取了適當的安全措施來保護此信息，防止未經授權的訪問，未經授權的更改，破壞或洩露數據。"
                    },
                    userterm_content: {
                        p1: "{{name}} 有權在任何時間及不需事先通知的情況下修改、取消、增加、替代任何在此列明的條款，而此條款均對本網站的使用者及註冊會員有效力 及約束力。因此，您應定期瀏覽本頁。如在本條款作出修訂後仍繼續使用本網站，即被視為接受這些修訂。\n當您使用{{name}} 或註冊成為本平台會員 的同時，您將會被視為接受本條款之所有規則約束。請在使用本平台前仔細閱讀所有相關條款。",
                        p12: "本條款最新修訂日期為 2023 年 5 月 28 日",
                        h2: "服務",
                        p2: " {{name}} 服務是暫時性的數位網路通訊服務，可增強Internet 安全性和隱私性。根據您的個人要求和您的全權酌情決定權，我們會自動提供不受監管和不受 監控的資料管道-虛擬私人網路（VPN）連線-供您專有使用，您對使用{{name}} 服務負全責。{{name}} 對使用者採取的行動不承擔任何責任，包括對已 執行或未執行的損害承擔刑事責任和民事責任。\n這些條款的其他規定進一步限制了{{name}} 的責任。\n我們授予您使用{{name}} 服務的許可，但要遵守這些 條款中規定的限制。您使{{name}} 服務的風險自負。{{name}} 服務可以隨時修改，更新，中斷或暫停，恕不另行通知或承擔責任。對於由此造成的對您的 任何傷害或其他不利後果，我們不承擔任何責任。{{name}} ，其所有者，員工，代理商和其他與{{name}} 服務相關的人員，對於因使用或向使用其註冊的任何 帳戶而產生或引起的任何已執行或未執行的任何形式的損害，概不負責。\n{{name}} 保證99.9％的服務正常運行時間。在任何情況下，{{name}} 對超出 客戶向{{name}} 支付的任何損失，金錢或非金錢損失概不負責，且不包括律師費或法院費用，與任何法律或其他成文法則無關。",
                        h3: "智慧財產權",
                        p3: "根據這些條款和條件，我們授予您有限的、非排他的、個人的、不可轉讓的、不可再許可的許可，以：<br /><ul> <li style={{ 'margin' : '8px 0 0 12px' }}>下載並使用{{name}} 軟體的副本；</li> <li style={{ 'margin': '8px 0 0 12px' }}> 使用{{name} } 服務，包括但不限於在{{name}} 軟體或我們的網站上或透過我們的網站提供的產品和服務。</li></ul>&nbsp;&nbsp;&nbsp; 根據{{name}} 服務，本協議未授予您任何其他權利或許可。本文提供的許可在終止之前一直有效。如果您不遵守本條款，許可將自動終止。{{name}} 服務（包括但不限於{{name }} 軟體，行動應用程式和所有其他項目）由{{name}} 擁有並擁有版權，並且在全球範圍內受到保護。我們保留對{{name}} 服務及其任何部分的權利，所有權和利益 ，包括但不限於所有版權，商標，服務標記，商業機密和其他智慧財產權。您不得採取任何行動來危害，限製或以任何方式乾擾我們對{{name}} 服務的所有權和權利。除非另有 說明，否則保留所有權利。",
                        h4: "禁止事項",
                        p4: `<p>
                        您對{{name}} 服務的存取和使用受這些條款以及所有適用法律和法規的約束。 對於任何違反任何適用法律或本條款的用戶，我們保留隨時酌情決定隨時終止或阻止其存取{{name}} 服務的權利，無論是否另行通知。
                        <br />
                        使用{{name}} 服務時，您自己同意不協助、鼓勵或允許其他人使用{{name}} 服務：
                        <br />
                        1) 用於不正當的、禁止的、違法、犯罪或詐欺活動，包括但不限於連接埠掃描，發送垃圾郵件，發送選擇接收的電子郵件，掃描開放中繼或開放代理，發送未經請求的電子 郵件或發送的任何版本或類型的電子郵件即使透過第三方伺服器路由電子郵件，任何彈出視窗啟動，使用被盜信用卡，信用卡欺詐，金融欺詐，加密貨幣欺詐，偽裝，敲詐，勒索，綁架，強姦， 謀殺，出售的信用卡被盜，出售被盜商品，提供或出售違禁，軍事和雙重用途商品，提供或出售受管制物質，身份盜竊，黑客入侵，偽造，網絡釣魚，任何形式或規模的刮取， 數位盜版，智慧財產權侵權和其他類似活動；
                        <br />
                        2) 透過{{name}} 服務以任何方式或形式攻擊，幹擾，獲得未經授權的訪問，拒絕任何其他網絡，電腦或節點的服務；
                        <br />
                        3) 以任何方式剝削兒童，包括音頻，視頻，攝影，數位內容等；
                        <br />
                        4) 以任何方式上傳或散佈包含病毒，蠕蟲，特洛伊木馬，損壞的檔案或任何其他可能損害他人電腦操作的類似軟體或程式的檔案；
                        <br />
                        5) 幹擾或試圖幹擾{{name}} 服務的正常運作，與{{name}} 服務有關的任何交易或我們進行的任何其他活動，破壞我們的網站或與{{name}} 服務相連的任何 網路或繞過任何措施我們可能會用來阻止或限制對{{name}} 服務的存取；
                        <br />
                        6) 以任何未經授權的方式利用{{name}} 服務，包括但不限於侵入或增加網路容量；
                        <br />
                        7) 未經我們事先書面許可，出於任何目的使用任何機器人，蜘蛛，刮刀或其他自動方式訪問我們的網站或{{name}} 服務；
                        <br />
                        8) 收集或收集有關{{name}} 服務其他使用者的個人資訊；
                        <br />
                        9) 未經合法利益或同意，或違反任何適用法律而收集或收集個人資料；
                        <br />
                        10) 採取任何可能會對我們的基礎架構施加不合理或不成比例的巨大負載的措施；
                        <br />
                        11) 與任何不合理數量的人分享任何資料或其他內容，包括但不限於向大量接收者發送爆炸性通訊或與您不認識或不認識您的人分享內容；
                        <br />
                        12) 代表您是任何{{name}} 服務（包括其任何功能）的代表或代理商；
                        <br />
                        13) 將{{name}} 服務或其任何部分合併到任何其他程式或產品中；
                        <br />
                        14) 以任何形式或任何方式複製或複製{{name}} 服務的任何部分；
                        <br />
                        15) 根據{{name}} 服務修改，翻譯，反向工程，反編譯，反彙編或創建任何衍生作品，包括其任何文件或文檔，或其任何部分，或確定或嘗試確定任何源代碼，演算法 ，方法或{{name}} 應用程式或其任何部分中包含的技術；
                        <br />
                        16) 違反一般的道德或道德規範，良好風俗和公平行為規範；
                        <br />
                         17) 侵犯任何第三方的權利，包括任何違反保密性，個人數據，版權或任何其他智慧財產權或所有權的權利；
                         <br />
                         18) 威脅，跟踪，傷害或騷擾他人，或助長偏執或歧視；
                         <br />
                         19) 試圖操縱{{name}} 名稱，服務或產品；
                         <br />
                         20) 向未成年人徵求或收集個人資料或與未成年人交流；
                         <br />
                         21) 試圖透過駭客攻擊，密碼挖掘，暴力破解或任何其他方式未經授權存取{{name}} 服務，使用者帳戶，連接到{{name}} 服務的電腦系統或網路；
                         <br />
                         22) 將{{name}} 服務用於任何軍事目的，包括網路戰，武器開發，設計，製造或生產飛彈，核，化學或生物武器；
                         <br />
                         23) 以其他方式侵犯或規避這些條款。
                         <br />
                         除此之外，長時間佔用寬頻進行下載導致伺服器網路異常，影響其他使用者正常使用的，{{name}} 會採取措施限制濫用 IP 的網路速度。
                         <br />
                         為了您的帳號安全，非客製化帳號僅限個人使用，請勿將帳號分享給他人，分享對他人造成的損失將由您自己承擔。
                         <br />
                         我們保留自行決定拒絕服務，暫停帳戶或限制存取{{name}} 服務的權利。 {{name}} 可以立即實施此類暫停或存取限制，而無需任何指示，通知或退款。 我們可能會暫停您的帳戶以進行澄清，調查，或要求您解釋您的操作並提供其他資訊。 如果您的帳戶已被暫停，則必須與我們聯繫以獲取更多資訊。 在我們永久終止使用者帳戶之前，我們可能會在合理的時間內暫停您的使用者帳戶。
                         <br />
                         我們沒有義務對您執行條款。 我們鼓勵您讓我們知道任何{{name}} 使用者違反這些條款的情況；但是，如果發生此類違規行為，我們可以自行決定採取適當的措施。
                         <br />
                         免責聲明
                         <br />
                         我們將努力防止網站和服務受到干擾。 但是，這些內容是根據「原樣」和「可用」的基礎提供的，我們不保證（明示或暗示）透過本網站或服務提供的任何資料或資訊的準確性，或它們對以下內容的適用性： 任何特定目的。 我們明確否認任何形式的擔保，無論是明示的還是暗示的，包括但不限於適銷性或針對特定目的的適用性或非侵權的擔保。 我們不保證本服務將滿足您的要求，也不保證其不間斷，及時，安全或無錯誤，也不對任何缺陷進行糾正。 您承認自己承擔風險和酌情權存取網站和服務。
                         <br />
                        {{name}} 保留調查我們認為違反這些條款的問題的權利。 我們可能會（但無義務）在不另行通知的情況下，自行決定，以任何方式刪除，阻止，過濾或限制我們認為是實際或潛在違反本條款所規定限制的任何材料或信息，以及任何其他 可能使{{name}} 或我們的客戶負責的活動。 {{name}} 對我們未能防止此類資料或資訊透過「服務」和/或進入您的計算設備的任何故障不承擔任何責任。
                       </p>`,
                        h5: "適用法律",
                        p5: "本協議應受新加坡法律的管轄並依其解釋，但其法律衝突規則除外。",
                        h6: "服務條款的變更",
                        p6: "根據適用的法律和原則，我們可能會不時更改條款服務條款，恕不另行通知。您繼續使用本網站或服務即表示您接受我們的隱私權政策。"
                    }
                }
            },
            en: {
                translation: {
                    app: "WuShuang VPN",
                    zhCN: "Simplified Chinese",
                    zhTW: "Chinese Traditional",
                    en: "English",
                    id: "Indonesian",
                    index: "Index",
                    pp: "Package",
                    download: "Download",
                    contact: "Contact Us",
                    privacy: "Privacy Policy",
                    userterm: "User Terms",
                    login: "Login",
                    name_error: "Name Error",
                    exiting: "Exiting",
                    exited: "Exit completed",
                    logout: "Log out of login",
                    app_download: "Application download",
                    banner_h1: "Data encryption, privacy protection, and internet security",
                    banner_p1: "Unlimited traffic, high-definition instant activation with no logs, anti tracking",
                    download_tips: "The development team is currently working overtime, you can try other platforms.",
                    other_platform: "Other platforms",
                    select_install_p: "Please select the installation method you want to download",
                    get_download_link: "Getting download link",
                    alipay: "Alipay",
                    to_pay: "Go to pay",
                    unselectgoodtype: "No product type selected",
                    params_error_tips: "Error in passing parameters, please check!",
                    select_goods: "Choose Package",
                    trade_goods_tips: "The unparalleled membership package can be used directly on the app after purchase. If you encounter any problems during the payment process, please contact us.",
                    original_price: "original price",
                    select_pay_type: "Choose payment method",
                    tips_not_input_email: "Email not entered",
                    tips_email_format_error: "Email format error",
                    tips_not_input_content: "No content entered",
                    tips_send_success: "Send successfully",
                    contact_us_content: "If you have any questions about the product, or want to make any comments or suggestions for us, please contact us",
                    please_input_email: "Please enter your email address and we will reply the results to this email address",
                    please_input_content: "Please enter the content you want to send us",
                    contact_content: "Please enter content",
                    your_email: "your email",
                    send: "send",
                    send_verify_code: "Send Code",
                    miss_email: "Missing email",
                    resend: "Resend after s",
                    pzinputEmail: "Please enter your email account",
                    pzinputcode: "Please enter the verification code",
                    loginerror: "Account or password is incorrect",
                    not_regist: "Not registered yet?",
                    register_success: "Registration successful",
                    not_input_account: "Account has not been entered yet",
                    send_code_error: "Error sending verification code",
                    regist: "Register",
                    go_login: "Go to login",
                    privacy_content: {
                        p1: "{{name}} Respect and fully protect user privacy. We hope you understand the information we collect, what information we do not collect and how we collect, use and store information. We do not collect your activity logs, including not recording browsing history, traffic data , data content, or DNS queries. We also never store connection logs, which means your IP address, connection time, or session duration are not logged",
                        h2: "Agree",
                        p2: "We attach great importance to user privacy, so we have developed a privacy policy. Please read the contents of these privacy policies carefully to help this platform provide you with satisfactory services. This policy covers how this platform collects information through the use of this platform. Personal data. By using the services of this website, you agree to accept the privacy policy of this platform and agree to the collection and use of personal data by this platform in accordance with the following policies.",
                        h3: "Mobile phone number & email",
                        p3: "When you register for this platform, you can choose to fill in your mobile phone number or email address, but they are not required. Failure to fill in will not affect account registration. We respect users' personal information very much and will not use it to meet third-party marketing purposes. and sell or rent any of your information to them.",
                        h4: "Age limit",
                        p4: "The Service is intended for adults over 18 years of age. If you believe that your child has provided us with information, please let us know immediately.",
                        h5: "Information Security",
                        p5: "You are also responsible for protecting and properly storing your personal information and avoiding sharing or disclosing your login password for this platform to others through any means (including phone calls and emails). When necessary, we will use strong encryption algorithms to securely All public and private keys and passwords are protected. Appropriate security measures are in place to protect this information from unauthorized access, unauthorized alteration, destruction or disclosure of data."
                    },
                    userterm_content: {
                        p1: "{{name}} has the right to modify, cancel, add, or replace any of the terms listed here at any time and without prior notice, and these terms are effective for users and registered members of this website. and binding. Therefore, you should browse this page regularly. If you continue to use this website after these terms are revised, you will be deemed to have accepted these revisions.\nWhen you use {{name}} or register as a member of this platform At the same time, you will be deemed to have accepted all the rules of these terms. Please read all relevant terms carefully before using this platform.",
                        p12: "This clause was last revised on May 28, 2023",
                        h2: "Service",
                        p2: "The {{name}} service is a temporary digital network communications service that enhances Internet security and privacy. Based on your individual requirements and at your sole discretion, we automatically provide unregulated and unregulated The monitored data pipe - a virtual private network (VPN) connection - is for your exclusive use and you are solely responsible for your use of the {{name}} service. {{name}} is not responsible for any actions taken by users, including criminal and civil liability for damages resulting from performance or failure to perform.\nOther provisions of these Terms further limit {{name}}'s liability.\nWe grant you a license to use the {{name}} Services, subject to these restrictions set forth in the Terms. You use the {{name}} Service at your own risk. The {{name}} Service may be modified, updated, interrupted or suspended at any time without notice or liability. You are responsible for any resulting liability to you. We disclaim all liability for any injury or other adverse consequences. {{name}}, its owners, employees, agents, and others associated with the {{name}} service, shall not be liable for any injury or other adverse consequences arising from the use of or registration with We are not responsible for any damages of any kind arising out of or arising out of your account, whether performed or not performed.\n{{name}} guarantees 99.9% service uptime. In no event will {{name}} be liable for any excess Client will not be liable for any damages, pecuniary or non-pecuniary, paid to {{name}} and shall be exclusive of attorneys' fees or court costs and not related to any law or other written law.",
                        h3: "Intellectual Property",
                        p3: "Subject to these Terms and Conditions, we grant you a limited, non-exclusive, personal, non-transferable, non-sublicensable license to:<br /><ul> <li style={{ 'margin' : '8px 0 0 12px' }}> Download and use a copy of the {{name}} software; </li> <li style={{ 'margin': '8px 0 0 12px' }}> Use {{name} } Services, including, but not limited to, products and services provided on or through the {{name}} Software or our website.</li></ul>&nbsp;&nbsp;&nbsp; Under {{name}} Services, this Agreement does not grant you any other rights or licenses. The license provided herein is effective until terminated. This license will automatically terminate if you fail to comply with these Terms. {{name}} Services (including, but not limited to, {{name }} software, mobile applications and all other items) are owned and copyrighted by {{name}} and are protected worldwide. We reserve right, title and interest in and to the {{name}} Services and any portion thereof , including without limitation all copyrights, trademarks, service marks, trade secrets and other intellectual property rights. You may not take any action that jeopardizes, restricts or in any way interferes with our ownership and rights in the {{name}} Services. Unless otherwise Description, otherwise all rights reserved.",
                        h4: "Prohibited matters",
                        p4: `<p>
                        Your access to and use of the {{name}} Services is subject to these Terms and all applicable laws and regulations. We reserve the right, at our sole discretion, to terminate or block access to the {{name}} Services at any time, with or without notice, to any user who violates any applicable law or these Terms.
                        <br />
                        When using {{name}} services, you agree not to assist, encourage or allow others to use {{name}} services:
                        <br />
                        1) For improper, prohibited, illegal, criminal or fraudulent activities, including but not limited to port scanning, sending spam, sending opt-in emails, scanning open relays or open proxies, sending unsolicited electronic messages Mail or send any version or type of email even if the email is routed through a third party server, any pop-up launch, use of stolen credit cards, credit card fraud, financial fraud, cryptocurrency fraud, impersonation, extortion, extortion, kidnapping, rape, Murder, stolen credit cards for sale, sale of stolen merchandise, offering or selling prohibited, military and dual use merchandise, offering or selling controlled substances, identity theft, hacking, counterfeiting, phishing, scraping of any shape or size, Digital piracy, intellectual property infringement and other similar activities;
                        <br />
                        2) Attack, interfere with, gain unauthorized access, or deny service to any other network, computer or node in any way or form through the {{name}} service;
                        <br />
                        3) Exploit children in any way, including audio, video, photography, digital content, etc.;
                        <br />
                        4) Upload or distribute in any way files that contain viruses, worms, Trojan horses, corrupted files, or any other similar software or programs that may damage the operation of another's computer;
                        <br />
                        5) Interfering or attempting to interfere with the normal working of the {{name}} service, any transactions related to the {{name}} service or any other activities conducted by us, disrupting our website or anything connected to the {{name}} service Network or bypass any measures we may use to block or restrict access to {{name}} services;
                        <br />
                        6) Exploit {{name}} services in any unauthorized manner, including but not limited to hacking or increasing network capacity;
                        <br />
                        7) Use any robot, spider, scraper or other automated means to access our Site or {{name}} Services for any purpose without our prior written permission;
                        <br />
                        8) Collect or collect personal information about other users of {{name}} services;
                        <br />
                        9) Collect or collect personal data without legitimate interests or consent, or in violation of any applicable law;
                        <br />
                        10) Take any action that may impose an unreasonable or disproportionately large load on our infrastructure;
                        <br />
                        11) Share any data or other content with any unreasonable number of people, including but not limited to sending explosive communications to a large number of recipients or sharing content with people you do not know or recognize;
                        <br />
                        12) Represent that you are a representative or agent of any {{name}} services (including any features thereof);
                        <br />
                        13) Incorporate the {{name}} Service or any portion thereof into any other program or product;
                        <br />
                        14) Copy or reproduce any part of the {{name}} Services in any form or by any means;
                         <br />
                         15) Modify, translate, reverse engineer, decompile, disassemble or create any derivative works based on the {{name}} Service, including any files or documentation thereof, or any part thereof, or determine or attempt to determine any source code, algorithm , methods or technology contained in the {{name}} Application or any part thereof;
                         <br />
                         16) Violates general moral or moral norms, good customs and fair conduct;
                         <br />
                         17) Violate the rights of any third party, including any breach of confidentiality, personal data, copyright or any other intellectual property or proprietary right;
                         <br />
                         18) Threaten, stalk, harm or harass others, or promote bigotry or discrimination;
                         <br />
                         19) Attempt to manipulate the name, services or products of {{name}};
                         <br />
                         20) Solicit or collect personal information from minors or communicate with minors;
                         <br />
                         21) Attempt to gain unauthorized access to the {{name}} service, user accounts, computer systems or networks connected to the {{name}} service through hacking, password mining, brute force or any other means;
                         <br />
                         22) Use {{name}} services for any military purpose, including cyber warfare, weapons development, design, manufacture or production of missile, nuclear, chemical or biological weapons;
                         <br />
                         23) Otherwise violate or circumvent these Terms.
                        <br />
                        In addition, if the long-term use of broadband for downloading causes server network abnormalities and affects the normal use of other users, {{name}} will take measures to limit the network speed of abused IPs.
                         <br />
                         For the security of your account, non-customized accounts are only for personal use. Please do not share your account with others. Any losses caused by sharing to others will be borne by you.
                         <br />
                         We reserve the right to refuse service, suspend accounts, or restrict access to {{name}} services in our sole discretion. {{name}} may impose such suspension or access restriction immediately without any instruction, notice or refund. We may suspend your account for clarification, investigation, or require you to explain your actions and provide additional information. If your account has been suspended, you must contact us for more information. We may suspend your User Account for a reasonable period of time before we permanently terminate it.
                         <br />
                         We have no obligation to enforce the Terms against you. We encourage you to let us know of any violation of these Terms by any user of {{name}}; however, if such a violation occurs, we may take appropriate action, at our sole discretion.
                         <br />
                         Disclaimer
                         <br />
                         We will work hard to prevent disruption to the Site and Services. However, they are provided on an "as is" and "as available" basis and we make no warranty (express or implied) as to the accuracy of any materials or information provided through the Site or Services, or their suitability for: any specific purpose. We expressly disclaim all warranties of any kind, whether express or implied, including, without limitation, warranties of merchantability or fitness for a particular purpose or non-infringement. We do not guarantee that the Service will meet your requirements, that it will be uninterrupted, timely, secure, or error-free, or that any defects will be corrected. You acknowledge that you access the Site and Services at your own risk and discretion.
                         <br />
                        {{name}} reserves the right to investigate matters that we believe violate these Terms. We may (but have no obligation), in our sole discretion and without notice, to remove, block, filter or restrict in any way any material or information that we consider to be an actual or potential breach of the restrictions set forth in these Terms, and any other Activities that may expose {{name}} or our clients to liability. {{name}} is not responsible for any failure on our part to prevent such material or information from passing through the Service and/or from entering your computing device.
                       </p>`,
                        h5: "Applicable law",
                        p5: "This Agreement shall be governed by and construed in accordance with the laws of Singapore, excluding its conflicts of law rules.",
                        h6: "Changes to Terms of Service",
                        p6: "In accordance with applicable laws and principles, we may change the Terms of Service from time to time without prior notice. Your continued use of this website or services constitutes your acceptance of our Privacy Policy."
                    }
                }
            },
            id: {
                translation: {
                    app: "WuShuang VPN",
                    zhCN: "Cina Sederhana",
                    zhTW: "Tradisional Tiongkok",
                    en: "Bahasa Inggris",
                    id: "Bahasa Indonesia",
                    index: "Indeks",
                    pp: "Paket",
                    download: "Unduh",
                    contact: "Hubungi Kami",
                    privacy: "Kebijakan Privasi",
                    userterm: "Ketentuan Pengguna",
                    login: "Masuk",
                    name_error: "Kesalahan Nama",
                    exiting: "Keluar",
                    exited: "Keluar selesai",
                    logout: "Keluar dari login",
                    app_download: "Unduh aplikasi",
                    banner_h1: "Enkripsi data, perlindungan privasi, dan keamanan internet",
                    banner_p1: "Lalu lintas tidak terbatas, aktivasi instan definisi tinggi tanpa log, anti pelacakan",
                    download_tips: "Tim pengembangan sedang bekerja lembur, Anda dapat mencoba platform lain.",
                    other_platform: "Platform lain",
                    select_install_p: "Silakan pilih metode instalasi yang ingin Anda unduh",
                    get_download_link: "Mendapatkan tautan unduhan",
                    alipay: "Alipay",
                    to_pay: "Pergi untuk membayar",
                    unselectgoodtype: "Tidak ada jenis produk yang dipilih",
                    params_error_tips: "Kesalahan dalam meneruskan parameter, harap periksa!",
                    select_goods: "Pilih Paket",
                    trade_goods_tips: "Paket keanggotaan yang tak tertandingi dapat digunakan langsung di aplikasi setelah pembelian. Jika Anda mengalami masalah selama proses pembayaran, silakan hubungi kami.",
                    original_price: "harga asli",
                    select_pay_type: "Pilih metode pembayaran",
                    tips_not_input_email: "Email tidak dimasukkan",
                    tips_email_format_error: "Kesalahan format email",
                    tips_not_input_content: "Tidak ada konten yang dimasukkan",
                    tips_send_success: "Kirim berhasil",
                    contact_us_content: "Jika Anda memiliki pertanyaan tentang produk, atau ingin memberikan komentar atau saran untuk kami, silakan hubungi kami",
                    please_input_email: "Silakan masukkan alamat email Anda dan kami akan membalas hasilnya ke alamat email ini",
                    please_input_content: "Silakan masukkan konten yang ingin Anda kirimkan kepada kami",
                    contact_content: "Silakan masukkan konten",
                    your_email: "email anda",
                    send: "kirim",
                    send_verify_code: "Kirim Kode",
                    miss_email: "Email tidak ada",
                    resend: "Kirim ulang setelah s",
                    pzinputEmail: "Silakan masukkan akun email Anda",
                    pzinputcode: "Silakan masukkan kode verifikasi",
                    loginerror: "Akun atau kata sandi salah",
                    not_regist: "Belum terdaftar?",
                    register_success: "Pendaftaran berhasil",
                    not_input_account: "Akun belum masuk",
                    send_code_error: "Kesalahan saat mengirim kode verifikasi",
                    regist: "Daftar",
                    go_login: "Masuk untuk login",
                    privacy_content: {
                        p1: "{{name}} Hormati dan lindungi sepenuhnya privasi pengguna. Kami harap Anda memahami informasi yang kami kumpulkan, informasi apa yang tidak kami kumpulkan, dan cara kami mengumpulkan, menggunakan, dan menyimpan informasi. Kami tidak mengumpulkan log aktivitas Anda, termasuk tidak merekam riwayat penelusuran, data lalu lintas, konten data, atau kueri DNS. Kami juga tidak pernah menyimpan log koneksi, yang berarti alamat IP, waktu koneksi, atau durasi sesi Anda tidak dicatat",
                        h2: "Setuju",
                        p2: "Kami sangat mementingkan privasi pengguna, jadi kami telah mengembangkan kebijakan privasi. Harap baca isi kebijakan privasi ini dengan cermat untuk membantu platform ini memberi Anda layanan yang memuaskan. Kebijakan ini mencakup bagaimana platform ini mengumpulkan informasi melalui penggunaan platform ini. Data pribadi. Dengan menggunakan layanan situs web ini, Anda setuju untuk menerima kebijakan privasi platform ini dan menyetujui pengumpulan dan penggunaan data pribadi oleh platform ini sesuai dengan kebijakan berikut.",
                        h3: "Nomor ponsel & email",
                        p3: "Saat Anda mendaftar untuk platform ini, Anda dapat memilih untuk mengisi nomor ponsel atau alamat email Anda, tetapi itu tidak wajib. Kegagalan mengisi tidak akan memengaruhi pendaftaran akun. Kami sangat menghormati informasi pribadi pengguna dan akan tidak menggunakannya untuk memenuhi tujuan pemasaran pihak ketiga, dan menjual atau menyewakan informasi Anda kepada mereka.",
                        h4: "Batas usia",
                        p4: "Layanan ini ditujukan untuk orang dewasa yang berusia di atas 18 tahun. Jika Anda yakin bahwa anak Anda telah memberikan informasi kepada kami, harap segera beri tahu kami.",
                        h5: "Keamanan Informasi",
                        p5: "Anda juga bertanggung jawab untuk melindungi dan menyimpan informasi pribadi Anda dengan benar dan menghindari membagikan atau mengungkapkan kata sandi login Anda untuk platform ini kepada orang lain melalui cara apa pun (termasuk panggilan telepon dan email). Bila diperlukan, kami akan menggunakan algoritma enkripsi yang kuat untuk mengamankan Semua kunci publik dan pribadi serta kata sandi dilindungi. Tindakan keamanan yang sesuai diterapkan untuk melindungi informasi ini dari akses tidak sah, perubahan tidak sah, penghancuran atau pengungkapan data."
                    },
                    userterm_content: {
                        p1: "{{name}} berhak untuk mengubah, membatalkan, menambah, atau mengganti ketentuan apa pun yang tercantum di sini kapan saja dan tanpa pemberitahuan sebelumnya, dan ketentuan ini berlaku untuk pengguna dan anggota terdaftar situs web ini. dan mengikat . Oleh karena itu, Anda harus menelusuri halaman ini secara rutin. Jika Anda terus menggunakan situs web ini setelah persyaratan ini direvisi, Anda akan dianggap telah menerima revisi ini.\nSaat Anda menggunakan {{name}} atau mendaftar sebagai anggota platform ini Pada saat yang sama, Anda akan dianggap telah menerima semua aturan ketentuan ini. Harap baca semua ketentuan yang relevan dengan cermat sebelum menggunakan platform ini.",
                        p12: "Klausul ini terakhir direvisi pada tanggal 28 Mei 2023",
                        h2: "Layanan",
                        p2: "Layanan {{name}} adalah layanan komunikasi jaringan digital sementara yang meningkatkan keamanan dan privasi Internet. Berdasarkan kebutuhan pribadi Anda dan atas kebijakan Anda sendiri, kami secara otomatis menyediakan pipa data yang dipantau dan tidak diatur - jaringan pribadi virtual Koneksi (VPN) - ditujukan untuk penggunaan eksklusif Anda dan Anda sepenuhnya bertanggung jawab atas penggunaan layanan {{name}} Anda. {{name}} tidak bertanggung jawab atas tindakan apa pun yang diambil oleh pengguna, termasuk tanggung jawab pidana dan perdata atas kerusakan yang diakibatkannya dari kinerja atau kegagalan untuk melakukan.\nKetentuan lain dalam Persyaratan ini selanjutnya membatasi tanggung jawab {{name}}.\nKami memberi Anda lisensi untuk menggunakan Layanan {{name}}, dengan tunduk pada pembatasan yang ditetapkan dalam Persyaratan. Anda menggunakan Layanan {{name}} dengan risiko Anda sendiri. Layanan {{name}} dapat dimodifikasi, diperbarui, diinterupsi, atau ditangguhkan kapan saja tanpa pemberitahuan atau kewajiban. Anda bertanggung jawab atas segala tanggung jawab yang diakibatkannya kepada Anda. Kami menafikan semua tanggung jawab atas cedera atau konsekuensi merugikan lainnya. {{name}}, pemiliknya, karyawan, agen, dan pihak lain yang terkait dengan layanan {{name}}, tidak bertanggung jawab atas cedera atau konsekuensi merugikan lainnya yang timbul dari penggunaan atau pendaftaran dengan Kami tidak bertanggung jawab atas apa pun segala jenis kerusakan yang timbul dari atau timbul dari akun Anda, baik dilakukan atau tidak dilakukan.\n{{name}} menjamin 99,9% waktu aktif layanan. Dalam keadaan apa pun {{name}} tidak akan bertanggung jawab atas kelebihan apa pun, Klien tidak akan bertanggung jawab atas segala kerusakan, berupa uang atau non-uang, yang dibayarkan kepada {{name}} dan tidak termasuk biaya pengacara atau biaya pengadilan dan tidak terkait terhadap hukum apa pun atau hukum tertulis lainnya.",
                        h3: "Kekayaan Intelektual",
                        p3: "Tunduk pada Syarat dan Ketentuan ini, kami memberi Anda lisensi terbatas, non-eksklusif, pribadi, tidak dapat dipindahtangankan, dan tidak dapat disublisensikan untuk:<br /><ul> <li style={{ 'margin' : ' 8px 0 0 12px' }}> Unduh dan gunakan salinan perangkat lunak {{name}}; </li> <li style={{ 'margin': '8px 0 0 12px' }}> Gunakan {{name} } Layanan, termasuk, namun tidak terbatas pada, produk dan layanan yang disediakan pada atau melalui Perangkat Lunak {{name}} atau situs web kami.</li></ul>&nbsp;&nbsp;&nbsp; Berdasarkan Layanan {{name}}, Perjanjian ini tidak memberi Anda hak atau lisensi apa pun. Lisensi yang diberikan di sini berlaku hingga diakhiri. Lisensi ini akan otomatis berakhir jika Anda gagal mematuhi Persyaratan ini. {{name}} Layanan (termasuk, namun tidak terbatas pada, { perangkat lunak {name }}, aplikasi seluler, dan semua item lainnya) dimiliki dan dilindungi hak cipta oleh {{name}} dan dilindungi di seluruh dunia. Kami memiliki hak, kepemilikan, dan kepentingan dalam dan atas Layanan {{name}} dan setiap bagiannya, termasuk namun tidak terbatas pada semua hak cipta, merek dagang, merek layanan, rahasia dagang, dan hak kekayaan intelektual lainnya. Anda tidak boleh mengambil tindakan apa pun yang membahayakan, membatasi, atau dengan cara apa pun mengganggu kepemilikan dan hak kami di Layanan {{name}}. Kecuali jika dijelaskan sebaliknya, semua hak dilindungi undang-undang.",
                        h4: "Hal-hal yang dilarang",
                        hal4: `<p>
                         Akses Anda ke dan penggunaan Layanan {{name}} tunduk pada Ketentuan ini dan semua hukum serta peraturan yang berlaku. Kami berhak, berdasarkan kebijakan kami sendiri, menghentikan atau memblokir akses ke Layanan {{name}} kapan saja, dengan atau tanpa pemberitahuan, kepada pengguna mana pun yang melanggar hukum yang berlaku atau Ketentuan ini.
                         <br />
                         Saat menggunakan layanan {{name}}, Anda setuju untuk tidak membantu, mendorong, atau mengizinkan orang lain menggunakan layanan {{name}}:
                         <br />
                         1) Untuk aktivitas yang tidak pantas, dilarang, ilegal, kriminal, atau penipuan, termasuk namun tidak terbatas pada pemindaian port, pengiriman spam, pengiriman email opt-in, pemindaian relay terbuka atau proxy terbuka, pengiriman pesan elektronik yang tidak diminta, Mail atau mengirim versi atau jenis apa pun email meskipun email dirutekan melalui server pihak ketiga, peluncuran pop-up apa pun, penggunaan kartu kredit curian, penipuan kartu kredit, penipuan keuangan, penipuan mata uang kripto, peniruan identitas, pemerasan, pemerasan, penculikan, pemerkosaan, Pembunuhan, kartu kredit curian untuk dijual, penjualan barang dagangan curian, menawarkan atau menjual barang terlarang, barang dagangan militer dan penggunaan ganda, menawarkan atau menjual zat-zat yang dikendalikan, pencurian identitas, peretasan, pemalsuan, phishing, pengikisan dalam bentuk atau ukuran apa pun, pembajakan digital, pelanggaran kekayaan intelektual dan sejenisnya kegiatan;
                         <br />
                         2) Menyerang, mengganggu, mendapatkan akses tidak sah, atau menolak layanan ke jaringan, komputer, atau node lain dengan cara atau bentuk apa pun melalui layanan {{name}};
                         <br />
                         3) Mengeksploitasi anak dengan cara apapun, termasuk audio, video, fotografi, konten digital, dll;
                         <br />
                         4) Mengunggah atau mendistribusikan dengan cara apa pun file yang mengandung virus, worm, Trojan horse, file rusak, atau perangkat lunak atau program serupa lainnya yang dapat merusak pengoperasian komputer orang lain;
                         <br />
                         5) Mengganggu atau mencoba mengganggu kerja normal layanan {{name}}, transaksi apa pun yang terkait dengan layanan {{name}} atau aktivitas lain apa pun yang kami lakukan, mengganggu situs web kami atau apa pun yang terkait dengan {{name}} }} layanan Jaringan atau lewati tindakan apa pun yang mungkin kami gunakan untuk memblokir atau membatasi akses ke layanan {{name}};
                         <br />
                         6) Memanfaatkan layanan {{name}} dengan cara apa pun yang tidak sah, termasuk namun tidak terbatas pada peretasan atau peningkatan kapasitas jaringan;
                         <br />
                         7) Menggunakan robot, spider, scraper, atau cara otomatis lainnya untuk mengakses Situs atau Layanan {{name}} kami untuk tujuan apa pun tanpa izin tertulis sebelumnya dari kami;
                         <br />
                         8) Mengumpulkan atau mengumpulkan informasi pribadi tentang pengguna layanan {{name}} lainnya;
                         <br />
                         9) Mengumpulkan atau mengumpulkan data pribadi tanpa kepentingan atau persetujuan yang sah, atau melanggar hukum yang berlaku;
                         <br />
                         10) Mengambil tindakan apa pun yang dapat menimbulkan beban besar yang tidak masuk akal atau tidak proporsional pada infrastruktur kami;
                         <br />
                         11) Berbagi data atau konten lainnya dengan jumlah orang yang tidak wajar, termasuk namun tidak terbatas pada mengirimkan komunikasi yang bersifat eksplosif ke sejumlah besar penerima atau berbagi konten dengan orang yang tidak Anda kenal atau kenali;
                         <br />
                         12) Menyatakan bahwa Anda adalah perwakilan atau agen dari layanan {{name}} apa pun (termasuk fitur apa pun di dalamnya);
                         <br />
                         13) Menggabungkan Layanan {{name}} atau bagian apa pun darinya ke dalam program atau produk lain;
                         <br />
                         14) Menyalin atau mereproduksi bagian mana pun dari Layanan {{name}} dalam bentuk apa pun atau dengan cara apa pun;
                          <br />
                          15) Memodifikasi, menerjemahkan, merekayasa balik, mendekompilasi, membongkar, atau membuat karya turunan apa pun berdasarkan Layanan {{name}}, termasuk file atau dokumentasi apa pun, atau bagian apa pun darinya, atau menentukan atau mencoba menentukan kode sumber, algoritme apa pun , metode atau teknologi yang terdapat dalam Aplikasi {{name}} atau bagian mana pun darinya;
                          <br />
                          16) Melanggar norma kesusilaan atau kesusilaan umum, adat istiadat yang baik dan perilaku yang adil;
                          <br />
                          17) Melanggar hak pihak ketiga mana pun, termasuk pelanggaran kerahasiaan, data pribadi, hak cipta, atau kekayaan intelektual atau hak kepemilikan lainnya;
                          <br />
                          18) Mengancam, menguntit, menyakiti atau melecehkan orang lain, atau mendorong kefanatikan atau diskriminasi;
                          <br />
                          19) Mencoba memanipulasi nama, layanan, atau produk {{name}};
                          <br />
                          20) Meminta atau mengumpulkan informasi pribadi dari anak di bawah umur atau berkomunikasi dengan anak di bawah umur;
                          <br />
                          21) Mencoba mendapatkan akses tidak sah ke layanan {{name}}, akun pengguna, sistem komputer atau jaringan yang terhubung ke layanan {{name}} melalui peretasan, penambangan kata sandi, kekerasan, atau cara lainnya;
                          <br />
                          22) Menggunakan layanan {{name}} untuk tujuan militer apa pun, termasuk perang dunia maya, pengembangan senjata, desain, pembuatan atau produksi senjata rudal, nuklir, kimia atau biologi;
                          <br />
                          23) Jika tidak, melanggar atau menghindari Ketentuan ini.
                         <br />
                         Selain itu, jika penggunaan broadband dalam jangka panjang untuk pengunduhan menyebabkan kelainan jaringan server dan memengaruhi penggunaan normal pengguna lain, {{name}} akan mengambil tindakan untuk membatasi kecepatan jaringan IP yang disalahgunakan.
                          <br />
                          Demi keamanan akun Anda, akun yang tidak dikustomisasi hanya untuk penggunaan pribadi. Harap jangan membagikan akun Anda dengan orang lain. Segala kerugian akibat berbagi kepada orang lain akan ditanggung oleh Anda.
                          <br />
                          Kami berhak menolak layanan, menangguhkan akun, atau membatasi akses ke layanan {{name}} berdasarkan kebijakan kami sendiri. {{name}} dapat segera menerapkan penangguhan atau pembatasan akses tersebut tanpa instruksi, pemberitahuan, atau pengembalian dana apa pun. Kami mungkin menangguhkan akun Anda untuk klarifikasi, penyelidikan, atau meminta Anda menjelaskan tindakan Anda dan memberikan informasi tambahan. Jika akun Anda ditangguhkan, Anda harus menghubungi kami untuk informasi lebih lanjut. Kami dapat menangguhkan Akun Pengguna Anda untuk jangka waktu yang wajar sebelum kami menghentikannya secara permanen.
                          <br />
                          Kami tidak memiliki kewajiban untuk menegakkan Ketentuan ini terhadap Anda. Kami mendorong Anda untuk memberi tahu kami jika ada pelanggaran terhadap Ketentuan ini yang dilakukan oleh pengguna {{name}}; namun, jika pelanggaran tersebut terjadi, kami dapat mengambil tindakan yang sesuai, berdasarkan kebijakan kami sendiri.
                          <br />
                          Penafian
                          <br />
                          Kami akan bekerja keras untuk mencegah gangguan pada Situs dan Layanan. Namun, hal-hal tersebut disediakan atas dasar "sebagaimana adanya" dan "sebagaimana tersedia" dan kami tidak memberikan jaminan (tersurat maupun tersirat) mengenai keakuratan materi atau informasi apa pun yang disediakan melalui Situs atau Layanan, atau kesesuaiannya untuk: hal tertentu tujuan. Kami secara tegas menyangkal semua jaminan dalam bentuk apa pun, baik tersurat maupun tersirat, termasuk, namun tidak terbatas pada, jaminan dapat diperjualbelikan atau kesesuaian untuk tujuan tertentu atau non-pelanggaran. Kami tidak menjamin bahwa Layanan akan memenuhi kebutuhan Anda, tidak akan terganggu, tepat waktu, aman, atau bebas dari kesalahan, atau bahwa setiap cacat akan diperbaiki. Anda mengakui bahwa Anda mengakses Situs dan Layanan atas risiko dan kebijaksanaan Anda sendiri.
                          <br />
                         {{name}} berhak menyelidiki hal-hal yang kami yakini melanggar Ketentuan ini. Kami dapat (tetapi tidak berkewajiban), atas kebijakan kami sendiri dan tanpa pemberitahuan, menghapus, memblokir, memfilter, atau membatasi dengan cara apa pun materi atau informasi apa pun yang kami anggap sebagai pelanggaran aktual atau potensial terhadap pembatasan yang ditetapkan dalam Ketentuan ini , dan Aktivitas lain apa pun yang dapat menyebabkan {{name}} atau klien kami bertanggung jawab. {{name}} tidak bertanggung jawab atas kegagalan kami dalam mencegah materi atau informasi tersebut melewati Layanan dan/atau memasuki perangkat komputasi Anda.
                        </p>`,
                        h5: "Hukum yang berlaku",
                        p5: "Perjanjian ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Singapura, tidak termasuk pertentangan aturan hukum.",
                        h6: "Perubahan Ketentuan Layanan",
                        p6: "Sesuai dengan hukum dan prinsip yang berlaku, kami dapat mengubah Ketentuan Layanan dari waktu ke waktu tanpa pemberitahuan sebelumnya. Jika Anda terus menggunakan situs web atau layanan ini, berarti Anda menerima Kebijakan Privasi kami."
                    }
                }
            }
        }
    });

export default i18n;