import React, { useEffect, useState } from "react";
import { queryPayReults } from "../api/api";
import SuccessPay from "../../public/success_pay.png";
import { useNavigate } from "react-router-dom";

const TradeModalView = (props) => {
  const { didComplete, cancelTrade, visible, mchTradeCode } = props;
  var orderStatus = 1;
  var timer = null;
  const navigate = useNavigate();
  const isEmpty = (obj) => {
    if (typeof obj == "undefined" || obj == null || obj == "") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    timer = setInterval(() => {
      if (orderStatus === 1) {
        if (isEmpty(mchTradeCode) === false) {
          queryPayReults({ orderId: mchTradeCode })
            .then((res) => {
              orderStatus = res.status;
              if (res.status === 0) {
                window.location.href = "/wice.html";
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        //支付成功
        clearInterval(timer);
      }
    }, 6000);

    return () => {
      clearInterval(timer);
    };
  }, [mchTradeCode]);

  const successPanel = () => {
    return (
      <div className="trade-modal-view">
        <div className="tarde-modal-panel">
          <img
            src={SuccessPay}
            alt="SuccessPay"
            style={{ width: "100px", height: "100px" }}
          ></img>
          <h3>支付成功</h3>
          <p style={{ marginBottom: "34px" }}>您的订单已成功支付</p>
          <div
            onClick={() => {
              clearInterval(timer);
              navigate("/");
            }}
            className="trade-modal-btn btn-border"
          >
            <a>完成</a>
          </div>
        </div>
      </div>
    );
  };

  const waitingPayPanel = () => {
    return (
      <div className="trade-modal-view">
        <div className="tarde-modal-panel">
          <h3>支付中</h3>
          <p>请在弹出的页面中完成支付</p>
          <div className="wait-plane">
            <div className="wait-point"></div>
            <div className="wait-point"></div>
            <div className="wait-point"></div>
          </div>
          <div
            onClick={() => {
              clearInterval(timer);
              didComplete();
            }}
            className="trade-modal-btn btn-background"
          >
            <a>已完成支付</a>
          </div>
          <div
            onClick={() => {
              clearInterval(timer);
              orderStatus = 0;
              cancelTrade();
            }}
            className="trade-modal-btn btn-border"
          >
            <a>取消支付</a>
          </div>
        </div>
      </div>
    );
  };

  const panel = (status) => {
    if (status === 1) {
      return waitingPayPanel();
    } else {
      return successPanel();
    }
  };

  return visible ? panel(orderStatus) : null;
};

export default TradeModalView;
