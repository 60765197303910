import http from "./https";
import validator from "email-validator";
import Cookies from "js-cookie";

const baseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "";
  }
  return "";
};

const getGoodsInfo = () => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/getGoodsInfo")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const userLogin = (data) => {
  return new Promise((resolve, reject) => {
    http
      .post(baseUrl() + "/wice/web/weblogin", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const orderGood = (data) => {
  return new Promise((resolve, reject) => {
    http
      .post(baseUrl() + "/wice/web/orderSadili", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sendVerificationCode = (data) => {
  return new Promise((resolve, reject) => {
    http.post(baseUrl() + "/wice/web/websendcode", data).then((response) => {
      resolve(response);
    })
      .catch((error) => {
        reject(error);
      });
  });
};

const saveS2sData = (data) => {
  return new Promise((resolve, reject) => {
    http.post(baseUrl() + "/wice/web/saveS2sData", data).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  })
}

const conversions = (title, eventId) => {
  var s2sBaseData = {
    "fbc": Cookies.get('_fbc'),// fb种的cookie, 点击了广告才存在
    "fbp": Cookies.get('_fbp'),// fb种的cookie, 接了像素就有的唯一id
    "client_user_agent": navigator.userAgent,
    "client_ip_address": '', // *** 服务端填充此值
    "action_source": 'website',
    "event_source_url": window.location.href,
    "event_id": eventId,//  md5(fbp+时间戳+随机数)
    "event_name": title,// 默认与track事件保持一致
    "event_time": 0,// *** 服务端需要更新此值
    "external_id": '',// 购买事件才传递，默认为fbp值；服务端如果有了用户唯一uid，可以更新此值
    "currency": '',// 购买事件才传递，默认为 CNY人民币
    "value": 0,// 购买事件才传递
    "fb_login_id": '',// 不接fb登录, 留空
    "em": '',// 不接fb登录, 留空
  };
  return new Promise((resolve, reject) => {
    http.post(baseUrl() + "/wice/web/conversions", s2sBaseData).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  })
}

const conversionsAdjust = (eventId, adjustId, event_params) => {
  const data = {
    event_token: eventId,
    idfa: adjustId,
    event_params: event_params
  }
  return new Promise((resolve, reject) => {
    http.post(baseUrl() + "/wice/web/conversions", data, { "api-version": "V2" }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  })
}

const register = (data) => {
  let account = data.account;
  var res = validator.validate(account);
  if (res === true) {
    data.registerType = "EMAIL";
  } else {
    data.registerType = "MOBILE";
  }
  return new Promise((resolve, reject) => {
    http
      .post(baseUrl() + "/wice/web/webregister", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const userterms = () => {
  return http.get(baseUrl() + "/wice/web/userterms");
};

const privacyInfo = () => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/privacyinfo")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const contactUs = (data) => {
  return http.post(baseUrl() + "/wice/web/contactus", data);
};

const isLogin = (data) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/isLogin", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const queryPayReults = (data) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/querypayresults", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getusernames = (data) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/getusername", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDownloadLinkUrl = (platform) => {
  return new Promise((resolve, reject) => {
    http
      .get(baseUrl() + "/wice/web/getDownloadLinks")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


function buildURL(p0, p1, p2, p3, p4, p5, p6, fbclid, fbpid) {
  var tracker_token, campaign, campaign, adgroup, adgroup, creative, creative, campaign, adgroup, creative, tracker_token
  if (!p0) {
    return null;
  }
  tracker_token = p0;
  if (p1 || p2) {
    campaign = p1 + "(" + p2 + ")";
  } else {
    campaign = "";
  }
  if (p3 || p4) {
    adgroup = p3 + "(" + p4 + ")";
  } else {
    adgroup = "";
  }
  if (p5 || p6) {
    creative = p5 + "(" + p6 + ")";
  } else {
    creative = "";
  }
  if (!fbclid) {
    fbclid = "";
  }
  if (!fbpid) {
    fbpid = "";
  }
  // build a tracker URL
  let params = { campaign, adgroup, creative, fbclid, fbpid };
  let newURL =
    "https://app.adjust.com/" +
    tracker_token +
    "?" +
    Object.keys(params)
      .map((key) => key + "=" + encodeURIComponent(params[key]))
      .join("&");
  return newURL;
}

//get fbpid from Cookie written by Facebook Pixel
const getFbPid = () => {
  let fbPid = Cookies.get('_fbp')
  if (Cookies.get('_fbp') == undefined) {
    return null
  } else {
    return fbPid
  }
}


const adjustTrackToken = () => {
  // get query string, and parse it with URLSearchParams
  const urlParams = new URLSearchParams(window.location.search);
  const p0 = urlParams.get("p0");
  const p1 = urlParams.get("p1");
  const p2 = urlParams.get("p2");
  const p3 = urlParams.get("p3");
  const p4 = urlParams.get("p4");
  const p5 = urlParams.get("p5");
  const p6 = urlParams.get("p6");
  const fbPid = getFbPid();
  const fbClickId = urlParams.get("fbclid");
  return buildURL(p0, p1, p2, p3, p4, p5, p6, fbClickId, fbPid);
}

export {
  getGoodsInfo,
  userLogin,
  orderGood,
  sendVerificationCode,
  register,
  userterms,
  privacyInfo,
  contactUs,
  queryPayReults,
  isLogin,
  getusernames,
  getDownloadLinkUrl,
  saveS2sData,
  conversions,
  conversionsAdjust,
  adjustTrackToken
};
