import React, { useState, useEffect } from "react";
import "../../asset/css/branch.scss";
import { privacyInfo } from "../../api/api";
import { useTranslation } from "react-i18next"

const Privacy = (props) => {
  const [dataSource, setDataSource] = useState({});
  const { t } = useTranslation()
  useEffect(() => {
    // privacyInfo().then((data) => {
    //   setDataSource(data);
    // });
  }, []);

  return (
    <div className="user-terms-body privacy-height" >
      <div className="content-card">
        <div>
          <div><h1>{t("privacy")}</h1><p>{t("privacy_content.p1", { name: t('app') })}</p></div>
          <div>
            <div>
              <h2>{t("privacy_content.h2")}</h2>
              <p>{t("privacy_content.p2")}</p>
            </div>
            <div>
              <h2>{t("privacy_content.h3")}</h2>
              <p>{t("privacy_content.p3")}</p>
            </div>
            <div>
              <h2>{t("privacy_content.h4")}</h2>
              <p>{t("privacy_content.p4")}</p>
            </div>
            <div>
              <h2>{t("privacy_content.h5")}</h2>
              <p>{t("privacy_content.p5")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
