import React from "react";
import logoText from "../../public/logo-text.png";
import { Row, Col } from "antd";

export default class Footer extends React.Component {

  render() {
    let _footer = {
      "kron": <p style={{ color: "white", margin: "0" }}>
        © 2023 Charon Technical Group Co., Ltd All rights reserved
      </p>,
      "pd": <p style={{ color: "white", margin: "0" }}>
        © 2023 Q Tech Co., Ltd All rights reserved
      </p>,
      "wus": <p style={{ color: "black", margin: "0" }}>
        © 2023 wusvpn Tech Co., Ltd All rights reserved
      </p>
    }

    return (
      <footer className="header-footer wus-footer-bar">
        {_footer[process.env.PROJECT]}
      </footer>
    );
  }
}
