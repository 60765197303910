import { react } from "react";
import "../asset/css/components.scss";
import { CloseIcon } from "./svgs";

const DownloadModal = (props) => {
    const { open, closeClickHanlde, children } = props
    return (
        <div className="download-modal" style={open ? { display: "flex" } : { display: "none" }}>
            <div className="download-content">
                {children}
            </div>
            <div className="modal-close-box" onClick={closeClickHanlde}>
                <CloseIcon></CloseIcon>
            </div>
        </div>
    )
}

export default DownloadModal