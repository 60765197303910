import React from "react";
import KronBannerImage from "../../public/banner_1.webp";
import WusBannerImage from "../../public/banner_2.webp";
import { Row, Col } from "antd";
import DownloadButton from "./download_button"
import { useTranslation } from 'react-i18next';

// xs: 8, sm: 16, md: 24, lg: 32
const Banner = (props) => {
  const { t } = useTranslation()
  const next = (e) => {
    let anchorName = "#download_app".replace("#", "");
    let anchorElement = document.getElementById(anchorName);
    if (anchorElement) {
      anchorElement.scrollIntoView();
    }
  };

  const BannerImage = {
    "wus": WusBannerImage,
    "kron": KronBannerImage
  }

  const BannerImageClassName = {
    "wus": "wus-banner-img",
    "kron": "banner-img"
  }

  const bannerClassName = {
    "kron": "banner",
    "wus": "wus-banner"
  }
  const banneContentXML = {
    "kron": <Col className="banner-item" xs={32} sm={32} md={6} lg={6}>
      <h1>数据加密，保护隐私保护您的上网安全</h1>
      <h3>一键获得更安全的上网保障</h3>
      <DownloadButton></DownloadButton>
    </Col>,
    "pd": <Col className="banner-item" xs={32} sm={32} md={6} lg={6}>
      <h1>数据加密，保护隐私保护您的上网安全</h1>
      <h3>一键获得更安全的上网保障</h3>
      <DownloadButton></DownloadButton>
    </Col>,
    "wus": <Col className="banner-item" xs={32} sm={32} md={14} lg={12} style={{ justifyContent: "center" }}>
      <h1>{t("banner_h1")}</h1>
      <p>{t("banner_p1")}</p>
      <DownloadButton></DownloadButton>
    </Col>
  }

  return (
    <Row
      id="banner"
      className={bannerClassName[process.env.PROJECT]}
      justify="space-around"
      align="center"
    >
      {banneContentXML[process.env.PROJECT]}
      <Col
        className="banner-item"
        xs={32}
        sm={32}
        md={14}
        lg={12}
        xl={12}
        xxl={8}
        style={{ justifyContent: "center" }}
      >
        <img
          // mode="contain"
          className={BannerImageClassName[process.env.PROJECT]}
          src={BannerImage[process.env.PROJECT]}
          alt={"Banner"}
        ></img>
      </Col>
    </Row>
  );
};

export default Banner;
