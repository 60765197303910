import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { getGoodsInfo } from "../../api/api";
import { message } from "antd";
import { orderGood, isLogin } from "../../api/api";
import TradeModalView from "../../components/trade_modal_view";
import "../../asset/css/login.scss";
import alipay from "../../asset/icon/alipay.svg";
import wechatpay from "../../asset/icon/wechatpay.svg";
import selected from "../../asset/icon/selected.svg";
import unselected from "../../asset/icon/unselected.svg";
import union from "../../asset/icon/union.svg"
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { ApiFilled } from "@ant-design/icons";
import { useUA } from 'use-ua-parser-js';
import { Erc20, Trc20 } from "../../components/svgs";
import { useTranslation } from 'react-i18next';

const Tarded = (props) => {
  const PayType = {
    1: "BANKPAY",
    2: "ALIPAY",
    3: "WECHATPAY",
  };

  const { isLogin, showHandler, hideHandler } = props;
  const params = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [dataSource, setDataSource] = useState([]);
  const [payButtonTitle, setPayButtonTitle] = useState("前往支付");
  const [visible, setVision] = useState(false);
  const [mchTradeCode, setMchTradeCode] = useState("");
  const [showUSDTAmount, setShowUSDTAmount] = useState(false)
  const UADetails = useUA();
  const listHeight = useRef()
  const { t } = useTranslation()
  const [payMethod, setPayMethod] = useState([
    {
      id: 1,
      title: t("alipay"),
      select: selected,
      payType: "alipay",
      icon: (
        <div>
          <img src={alipay} alt={"alipay"} />
        </div>
      ),
    },
    // {
    //   id: 2,
    //   title: "USDT(TRC-20)",
    //   select: unselected,
    //   payType: "usdt",
    //   cryptoProtocol: "TRC-20",
    //   icon: (
    //     <Trc20></Trc20>
    //   ),
    // },
    // {
    //   id: 3,
    //   title: "USDT(ERC-20)",
    //   select: unselected,
    //   payType: "usdt",
    //   cryptoProtocol: "ERC-20",
    //   icon: (
    //     <Erc20></Erc20>
    //   ),
    // }
  ]);

  const getUrlToken = (name, str) => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [itemSelectedIndex, setItemSelectedIndex] = useState(-1);


  useEffect(() => {
    const session = getUrlToken("session", location.search);
    const name = getUrlToken("name", location.search);
    const userId = getUrlToken("userId", location.search);
    const goodsId = getUrlToken("goodsId", location.search);
    console.log(isLogin)

    console.log(listHeight.current.clientHeight)

    if (
      session !== undefined &&
      name !== undefined &&
      userId !== undefined &&
      session !== null &&
      name !== null &&
      userId !== null
    ) {
      Cookies.set("session", session);
      let userInfo = { name: name, user_id: userId };
      Cookies.set("user-info", JSON.stringify(userInfo));
      hideHandler(userInfo);
    }
    getGoodsInfo().then((goods) => {
      goods.forEach((good, index) => {
        if (params.state === null && goodsId === null) {
          if (good.id == 1) {
            good.isSelected = true;
            setPayButtonTitle(
              showUSDTAmount ? (good.usdt_amount * good.discount) / 100 +
                " " +
                "USDT" +
                "  " + t("to_pay") : (good.amount * good.discount) / 100 +
                " " +
                good.amount_type +
                "  " + t("to_pay")
            );
            setItemSelectedIndex(index);
          } else {
            good.isSelected = false;
          }
        } else if (goodsId !== null) {
          if (good.id === goodsId) {
            good.isSelected = true;
            setPayButtonTitle(
              showUSDTAmount ? (good.usdt_amount * good.discount) / 100 +
                " " +
                "USDT" +
                "  " + t("to_pay") : (good.amount * good.discount) / 100 +
                " " +
                good.amount_type +
                "  " + t("to_pay")
            );
            setItemSelectedIndex(index);
          } else {
            good.isSelected = false;
          }
        } else {
          if (good.id == params.state.id) {
            good.isSelected = true;
            setPayButtonTitle(
              showUSDTAmount ? (good.usdt_amount * good.discount) / 100 +
                " " +
                "USDT" +
                "  " + t("to_pay") : (good.amount * good.discount) / 100 +
                " " +
                good.amount_type +
                "  " + t("to_pay")
            );
            setItemSelectedIndex(index);
          } else {
            good.isSelected = false;
          }
        }
      });
      setDataSource(goods);
    });
  }, []);

  const tradeCardItemHandle = (item, key) => {
    if (itemSelectedIndex > -1) {
      var oldItem = dataSource[itemSelectedIndex];
      oldItem.isSelected = false;
      dataSource.splice(itemSelectedIndex, 1, oldItem);
    }
    item.isSelected = true;
    dataSource.splice(key, 1, item);
    setItemSelectedIndex(key);
    setDataSource(dataSource);

    setPayButtonTitle(
      showUSDTAmount ? (item.usdt_amount * item.discount) / 100 +
        " " +
        "USDT" +
        "  " + t("to_pay") : (item.amount * item.discount) / 100 +
        " " +
        item.amount_type +
        "  " + t("to_pay")
    );
  };

  const payMethodClickHandler = (item) => {
    var oldItem = payMethod[selectedIndex];
    oldItem.select = unselected;
    if (selectedIndex > 0) {
      payMethod.splice(oldItem.id - 1, 1, oldItem);
    }

    item.select = selected;
    payMethod.splice(item.id - 1, 1, item);
    setSelectedIndex(item.id - 1);
    if (item.payType == "usdt") {
      setShowUSDTAmount(true)
    } else {
      setShowUSDTAmount(false)
    }
    setPayMethod(payMethod);

    const goods = dataSource[itemSelectedIndex]
    if (goods != undefined) {
      setPayButtonTitle(
        item.payType == "usdt" ? (goods.usdt_amount * goods.discount) / 100 +
          " " +
          "USDT" +
          "  " + t("to_pay") : (good.amount * good.discount) / 100 +
          " " +
          good.amount_type +
          "  " + t("to_pay")
      );
    }
  };

  const getParamValue = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName);
  }

  const goToPay = async () => {
    if (itemSelectedIndex < 0) {
      message.error(t("unselectgoodtype"));
      return;
    }
    const paymethod = payMethod[selectedIndex]


    if (isLogin) {
      var platform = "ios"
      if (UADetails.os.name.toLowerCase() != platform) {
        platform = "android"
      }
      let w = window.open("", "_blank");
      var res = await orderGood({ goodsId: dataSource[itemSelectedIndex].goods_id, payType: paymethod.payType, cryptoProtocol: paymethod.cryptoProtocol, platform: platform, channel_id: getParamValue("channel_id") })
      if (res.status === 0) {
        w.location.href = res.result.payUrl;
        setMchTradeCode(res.result.orderNo);
        setVision(true);
      } else {
        message.error(res.data)
        w.close()
      }
    } else {
      showHandler()
    }
  };

  // const orderGoods = async (item) => {
  //   if (isLogin == false) {
  //     showHandler();
  //     return;
  //   }
  //   var platform = "ios"
  //   if (UADetails.os.name.toLowerCase() != platform) {
  //     platform = "android"
  //   }
  //   let hide = messageApi.loading("正在创建订单")

  //   var value = await orderGood({ goodsId: item.goods_id, platform: platform, channel_id: getParamValue("channel_id") })
  //   setTimeout(hide, 100);
  //   if (value.status === 0) {
  //     setOrderId(value.result.orderId)
  //     if (UADetails.os.name === "iOS" || UADetails.os.name === "Android") {
  //       window.location.href = value.result.orderInfo
  //     } else {
  //       setIsModalOpen(true)
  //       setPayUrl(value.result.orderInfo)
  //     }
  //   }
  // };

  const didComplete = () => {
    console.log("didComplete");
    setVision(false);
    navigate("/");
  };

  const cancelTrade = () => {
    console.log("cancelTrade");
    setVision(false);
  };

  const keepTwoDecimal = (num) => {
    var result = parseFloat(num);
    if (isNaN(result)) {
      alert(t("params_error_tips"));
      return false;
    }
    result = Math.round(num * 100) / 100;
    return result;
  };

  const getRealCharge = (item) => {
    if (showUSDTAmount == true) {
      const charge = (item.usdt_amount * item.discount) / 100;
      return keepTwoDecimal(charge);
    } else {
      const charge = (item.amount * item.discount) / 100;
      return keepTwoDecimal(charge);
    }
  };

  return (
    <div className="trade-body" style={{ textAlign: "center", padding: "160px 20px 60px 20px" }} ref={listHeight}>
      <h1>{t("select_goods")}</h1>
      <p>{t("trade_goods_tips")}</p>
      <div className="trade-list">
        {dataSource.map((item, key) => (
          <div
            key={key}
            className="tarde-item"
            onClick={tradeCardItemHandle.bind(this, item, key)}
            style={item.isSelected ? { border: "3px solid #FF374E", background: "linear-gradient(149deg, #FFFCFC 20.22%, #FFDEE2 97.68%)" } : null}
          >
            <div className="wus-trade-discount wus-trade-discount-position wus-trade-discount-text">
              {((1 - item.discount) * 100).toFixed(0)}% OFF
            </div>
            {item.isSelected ? (
              <img className="trade-pay-item-select-icon" src={selected}></img>
            ) : null}
            <div className="trade-item-title">{item.goods_info}</div>
            <div id="charge">
              <p className="trade-charge-p">{getRealCharge(item)}</p>
              <p className="trade-charge-type-p">{showUSDTAmount ? "usdt" : item.amount_type}</p>
            </div>
            <div className="trade-discount">
              {/* <div id="discount">
                <p>{item.discount * 100}% OFF</p>
              </div> */}
              <div>
                <div className="trade-old-charge" style={{ textDecoration: "line-through" }}>
                  {t("original_price")}{showUSDTAmount ? keepTwoDecimal(item.usdt_amount / 100) + "$" : keepTwoDecimal(item.amount / 100) + "¥"}
                </div>
              </div>
              <div style={{ marginTop: "1em" }}>
                <p className="trade-old-charge" >{item.goods_info}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h1>{t("select_pay_type")}</h1>
      <div className="trade-pay-method">
        {payMethod.map((item, key) => (
          <div
            key={key}
            className="trade-pay-method-item"
            onClick={payMethodClickHandler.bind(this, item)}
            style={item.select == selected ? { border: "4px solid var(--primary-70, #FE2C55)" } : { border: "1px solid #F0F3F7" }}
          >
            <span>
              <img
                src={item.select}
                alt={item.title}
                style={{ marginRight: "5px" }}
              />
              {item.title}
            </span>
            {item.icon}
          </div>
        ))}
      </div>
      <div className="trade-pay-button trade-pay-button-text" onClick={goToPay.bind(this)}>
        {payButtonTitle}
      </div>
      <TradeModalView
        visible={visible}
        mchTradeCode={mchTradeCode}
        didComplete={didComplete.bind(this)}
        cancelTrade={cancelTrade.bind(this)}
      ></TradeModalView>
    </div >
  );
};
const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideHandler: (userInfo) => {
      dispatch({
        type: "HIDEMODAL",
        state: { userInfo },
      });
    },
    showHandler: (goods) => {
      dispatch({
        type: "SHOWMODAL",
        modalType: "LOGIN",
        model: goods,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tarded);
