import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom'
import { connect } from "react-redux";
import "../asset/css/components.scss";
import Cookies from "js-cookie";

const UserMenu = (props) => {
  const { onShow } = props
  const { loginOutHandle } = props;
  const [show, setShow] = useState(false);
  const ref = useRef();

  useEffect(() => {
    setShow(onShow)
  }, [show]);


  return (
    <div className={`user-menu ${onShow ? 'show' : ''}`} ref={ref}>
      <div>
        <a
          style={{ color: "#ffffff" }}
          onClick={() => {
            loginOutHandle();
          }}
        >
          退出登录
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispathchToProps = (dispath) => {
  return {
    loginOutHandle: () => {
      dispath({
        type: "LOGOUT",
      });
    },
  };
};

export default connect(mapStateToProps, mapDispathchToProps)(UserMenu);
