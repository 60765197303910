import Cookies from "js-cookie";


const ModalType = {
  Buy: "BUY",
  Login: "LOGIN",
  Register: "REGISTER",
};

// state可以设置默认值
const initState = {
  showModal: false,
  isLogin: Cookies.get("session") !== undefined ? true : false,
  userInfo:
    Cookies.get("user-info") !== undefined
      ? JSON.parse(window.atob(Cookies.get("user-info")))
      : {},
  modalType: "LOGIN",
  downloadLinks: {},
  language: navigator.language || navigator.languages[0]
};

const reducer = (state = initState, action) => {
  let info = Cookies.get("user-info") !== undefined
    ? JSON.parse(window.atob(Cookies.get("user-info")))
    : {}
  switch (action.type) {
    case "SHOWMODAL":
      return {
        showModal: true,
        modalType: action.modalType,
        isLogin: Cookies.get("session") !== undefined ? true : false,
        userInfo: state.userInfo,
        model: action.model,
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "HIDEMODAL":
      return {
        showModal: false,
        modalType: state.modalType,
        isLogin: Cookies.get("session") !== undefined ? true : false,
        userInfo: info,
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "LOGINED":
      return {
        isLogin: true,
        modalType: state.modalType,
        showModal: state.showModal,
        userInfo: state.userInfo,
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "LOGOUT":
      Cookies.remove("user-info");
      Cookies.remove("session");
      return {
        isLogin: false,
        modalType: state.modalType,
        showModal: state.showModal,
        userInfo: "",
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "UPDATE_INFO":

      return {
        isLogin: state.isLogin,
        showModal: state.showModal,
        userInfo: info,
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "REGISTER":
      return {
        showModal: state.showModal,
        modalType: "REGISTER",
        isLogin: state.isLogin,
        userInfo: state.userInfo,
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "LOGIN":
      return {
        showModal: state.showModal,
        modalType: "LOGIN",
        isLogin: state.isLogin,
        userInfo: state.userInfo,
        downloadLinks: state.downloadLinks,
        language: state.language
      };
    case "DOWNLOADLINKS":
      return {
        showModal: state.showModal,
        isLogin: state.isLogin,
        userInfo: state.userInfo,
        downloadLinks: action.state.downloadLinks,
        language: state.language
      }
    case "LANGUAGE":
      return {
        showModal: state.showModal,
        isLogin: state.isLogin,
        userInfo: state.userInfo,
        downloadLinks: state.downloadLinks,
        language: action.language
      }
    default:
      return state;
  }
};

export default reducer;
