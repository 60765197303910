import React from "react";
import ReactDOM from "react-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import WiceBody from "./wice_body";
import Trade from "./trade";
import ContactUs from "./contact_us";
import Privacy from "./privacy";
import UserTerms from "./user_terms";
import CustomModal from "../../components/custom_modal";
import { Provider } from "react-redux";
import store from "../../store/index";
import "../../asset/css/index.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import "../../asset/i18n"

const BodyContentClassName = {
  "wus": "wus-body-content",
  "kron": "body-content",
  "pd": "body-content"
}


ReactDOM.render(
  <I18nextProvider>
    <div className={BodyContentClassName[process.env.PROJECT]}>
      <Router>
        <Provider store={store}>
          <Header></Header>
          <Routes>
            <Route path="/" exact element={<WiceBody />}></Route>
            <Route path="/index.html" exact element={<WiceBody />}></Route>
            {/* <Route path="/trade" element={<Trade />}></Route> */}
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/user-terms" element={<UserTerms />}></Route>
          </Routes>
          <CustomModal></CustomModal>
        </Provider>
      </Router>
      <Footer></Footer>
    </div>
  </I18nextProvider>
  ,
  document.getElementById("root")
);
