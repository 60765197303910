import React, { useState, useEffect } from "react";
import store from "../store/index";
import { Popover, message } from "antd";
import { AppleFilled, WindowsFilled, AndroidFilled } from "@ant-design/icons";
import { QRCodeIcon, MacOSIcon, TvIcon, HarmonyOSIcon } from "./svgs";
import { getDownloadLinkUrl } from "../api/api";
import { connect } from "react-redux";
import download_button from "./download_button";
import { useTranslation } from 'react-i18next';
const DownloadApp = (props) => {
  const { downloadLinks } = props
  const [dataSource, setDataSource] = useState([]);
  const { t } = useTranslation()
  const DownIconStyle = {
    width: "40px",
    fontSize: "40px"
  }
  const DownloadIcons = {
    "mac": {
      icon: <MacOSIcon style={DownIconStyle}></MacOSIcon >,
      name: "Mac OS"
    },
    "android": {
      icon: <AndroidFilled style={DownIconStyle}></AndroidFilled>,
      name: "android"
    },
    "ios": {
      icon: <AppleFilled style={DownIconStyle}></AppleFilled>,
      name: "ios"
    },
    "windows": {
      icon: <WindowsFilled style={DownIconStyle}></WindowsFilled>,
      name: "windows"
    },
    "androidTV": {
      icon: <TvIcon style={DownIconStyle}></TvIcon>,
      name: "androidTV"
    },
    "HarmonyOS": {
      icon: <HarmonyOSIcon style={DownIconStyle}></HarmonyOSIcon>,
      platform: "android"
    }
  }

  useEffect(async () => {
    store.subscribe(() => {
      if (store.getState().downloadLinks.length === undefined) {
        setDataSource([])
        return
      }
      setDataSource(store.getState().downloadLinks)
    })
    return () => {
      store.unsubscribe()
    };
  }, [downloadLinks]);


  const downloadAppHandle = (item) => {
    window.href = item.download_path;
    const link = document.createElement('a');
    link.href = item.download_path
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="download_app" className="download-app" align="center">
      <h1 style={{ marginBottom: "80px" }}>{t("app_download")}</h1>
      <div className="download-app-list" >
        {dataSource.map((item, key) => (
          <div key={key} className="download-app-item">
            <div className="download-app-item-front">
              {DownloadIcons[item.platform].icon}
              <h1>{item.platform}</h1>
            </div>
            <div className="download-app-item-before" title="Title">
              <div
                style={{
                  background: "black",
                  color: "white",
                  width: "120px",
                  height: "54px",
                  borderRadius: "8px",
                  padding: "auto",
                  cursor: "pointer",
                }}
                onClick={() => {
                  downloadAppHandle(item)
                }}
              >
                <p>{t("download")}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    downloadLinks: state.downloadLinks,
  };
}

export default connect(mapStateToProps, null)(DownloadApp);
