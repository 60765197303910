import React, { useEffect, useState } from "react";
import { Anchor } from "antd";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import closeLined from "../../public/close.svg"
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
const { Link } = Anchor;
const DropdownMenu = (props) => {
  const { groups, onVision, setVision, loginOutHandle, showModalHandle, isLogin, userInfo, onClickClose } =
    props;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation()
  useEffect(() => {

  }, [groups]);


  const linkClickHandler = (item) => {
    setVision(false)
    if (item.href === "#login") {
      showModalHandle();
    } else if (item.href === "#logout") {
      loginOutHandle();
    } else if (item.href === "/contact-us") {
      navigate("/contact-us");
    } else if (item.href === "/privacy") {
      navigate("/privacy");
    } else if (item.href === "/user-terms") {
      navigate("/user-terms");
    } else if (item.href === "/trade") {
      navigate("/trade");
    } else {
      navigate("/");
    }
  };

  const CloseIcon = () => {
    return <img src={closeLined} onClick={onClickClose} />
  }

  const onLoginHandle = () => {
    if (isLogin) {
      loginOutHandle()
    } else {
      showModalHandle()
    }
  }
  return (
    <div {...props}>
      <div className="close-box">
        <CloseIcon />
      </div >
      {isLogin ? <div className="anchor-link-cus" style={{ padding: "10px 20px" }}>
        {userInfo.email}
      </div> : <div></div>}
      <Anchor affix={false}>
        {groups.map((items, k1) =>
          items.map((item, k2) => (
            <div
              key={k1 * 10 + k2}
              onClick={linkClickHandler.bind(this, item)}
            >

              <Link
                key={k1 * 10 + k2}
                title={item.title}
                href={item.href}
                className={`anchor-link-cus ${location.pathname === item.href ? "select-font-color" : ""}`}
              ></Link>
            </div>
          ))
        )}
      </Anchor>
      <div className="dropmenu-button position" onClick={onLoginHandle}>
        {isLogin ? t("logout") : t("login")}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    userInfo: state.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginOutHandle: () => {
      dispatch({
        type: "LOGOUT",
      });
    },
    showModalHandle: () => {
      dispatch({
        type: "SHOWMODAL",
        modalType: "LOGIN",
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownMenu);
