import React from "react";
import { Form, Input, Button, message } from "antd";
import "../../asset/css/branch.scss";
import { contactUs } from "../../api/api";
import validator from "email-validator";
import { MailFilled } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const ContactUs = (props) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const contactUsClassName = {
    "kron": "contact-us",
    "pd": "contact-us",
    "wus": "wus-contact-us"
  }

  const { t } = useTranslation()

  const sendAdvicesHandle = (e) => {
    console.log(e);
    if (e.email === undefined) {
      message.error(t("tips_not_input_email"));
      return;
    }
    var res = validator.validate(e.email);

    if (res !== true) {
      message.error(t("tips_email_format_error"));
      return;
    }

    if (e.content === undefined) {
      message.error(t("tips_not_input_content"));
      return;
    }

    contactUs(e)
      .then((data) => {
        if (data.status === 0) {
          message.success(t("tips_send_success"));
          console.log(form);
          form.resetFields();
        }
      })
      .catch((err) => { });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className={contactUsClassName[process.env.PROJECT]}>
      <div className="contact-us-title">
        <h1>{t("contact")}</h1>
        <p>
          {t("contact_us_content")}
        </p>
      </div>
      <div className="contact-us-form">
        <Form
          name="contact-us-form"
          onFinish={sendAdvicesHandle}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{ remember: true }}
          form={form}
        >
          <p>{t("please_input_email")}</p>
          <Form.Item name="email">
            <Input addonBefore={<MailFilled style={{ color: "#FE2C55" }}></MailFilled>} placeholder={t("your_email")} ></Input>
          </Form.Item>
          <p>{t("please_input_content")}</p>
          <Form.Item name="content">
            <TextArea
              showCount
              maxLength={200}
              placeholder={t("contact_content")}
              style={{ height: "164px" }}
            ></TextArea>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" className="contact-us-btn contact-us-btn-text">
              {t("send")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ContactUs;
