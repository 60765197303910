import axios from "axios";
import sortJson from 'sort-json';
import SHA256 from 'sha256-es';
import store from '../store/index';

axios.defaults.withCredentials = false
axios.defaults.timeout = 100000
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.interceptors.request.use(config => {
    // config.setHeaders(config)
    const state = store.getState();
    config.headers["localized"] = state.language
    return config
})

// axios拦截器
axios.interceptors.response.use(response => {
    // 在这里你可以判断后台返回数据携带的请求码
    if (response === undefined) { return response }
    if (response.status === 200 || response.status === '200') {
        return response.data
    } else {
        // 非200请求抱错
        //throw Error(response.data.msg || '服务异常')
    }
})

const get = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then((response) => {
            if (response.status === 0)
                resolve(response.result)
        }).catch((error) => {
            reject(error)
        })
    })
}

//"x0drfi48zqymout1"
// "06befcd3375f9a6856b2b84485ce80003ea50a2764ac6d59d22e9a006e5fe7f8"	
const post = (url, data, header) => {
    return new Promise((resolve, reject) => {
        const copy = sortJson(data);
        if (data !== undefined || data.length > 0) {
            var sign = ""
            Object.keys(copy).forEach((key) => {
                sign = sign + key + "=" + copy[key] + "&"
            })
            sign = sign + "key=x0drfi48zqymout1"
            copy["signature"] = SHA256.hash(sign);
        }
        var config = {}
        if (header != undefined) {
            config.headers = header
        }
        return axios.post(url, copy, config).then((response) => {
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

const http = { get, post }
export default http
