import React, { useState, useEffect, useRef } from "react";
import { Anchor, Dropdown, Space, Typography } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';
import logoText from "../../public/logo-text.png";
import pdlogo from "../../public/logo-text_12.png"
import wuslogo from "../../public/logo-text-wus.png"
import wuslogoWhite from "../../public/logo-text-wus-w.png"
import { AvatarDefault } from "./svgs";
import "antd/dist/antd.min.css";
import { MenuIcon, AccountIcon } from "./svgs";
import DropdownMenu from "./dropdown_menu";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import UserMenu from "./user-menu";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from 'react-i18next';

const { Link } = Anchor;
const Header = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const { showHandler, isLogin, userInfo, changeLanguage, language } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const ref = useRef();
  const { t, i18n } = useTranslation();
  const items = [
    {
      key: 'zh-CN',
      label: t('zhCN'),
    },
    {
      key: 'zh-TW',
      label: t('zhTW'),
    },
    {
      key: 'en',
      label: t('en'),
    },
    {
      key: 'id',
      label: t('id'),
    },
  ];

  const [logos, setlogos] = useState({
    "kron": <img src={logoText} alt="Logo" />,
    "pd": <img src={pdlogo} alt="Logo" />,
    "wus": <img src={wuslogo} alt="Logo" />
  })
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState({
    "kron": { backgroundColor: "#010E55" },
    "pd": { backgroundColor: "#010E55" },
    "wus": { backgroundColor: "rgba(255, 55, 78, 0)" },
  })

  const navClassName = {
    "kron": "header-nav",
    "pd": "header-nav",
    "wus": "wus-header-nav",
  }

  const [menuColor, setMenuColor] = useState({
    "kron": { color: showMenu ? "#FFFFFF" : "#fff" },
    "pd": { color: showMenu ? "#FFFFFF" : "#fff" },
    "wus": { color: showMenu ? "#FFCD31" : "#000" }
  })

  const [liTextColor, setLiTextColor] = useState({
    "kron": { color: "#FFFFFF" },
    "pd": { color: "#FFFFFF" },
    "wus": { color: "#000000" }
  })

  var groupFront = {
    "kron": [
      { title: t("index"), href: "#banner" },
      // { title: "产品功能", href: "#introduce" },
      { title: "价格&套餐", href: "#buy" },
      { title: "下载", href: "#download_app" },
    ],
    "pd": [
      { title: "首页", href: "#banner" },
      // { title: "产品功能", href: "#introduce" },
      { title: "价格&套餐", href: "#buy" },
      { title: "下载", href: "#download_app" },
    ],
    "wus": [
      { title: t("index"), href: "/" },
      // { title: "产品功能", href: "#introduce" },
      // { title: t("pp"), href: "/trade" }
    ]
  };

  var groupBefore = [
    { title: t("contact"), href: "/contact-us" },
    { title: t("privacy"), href: "/privacy" },
    { title: t("userterm"), href: "/user-terms" },
  ];



  const onClick = ({ key }) => {
    changeLanguage(decorateLanguage(key))
    i18n.changeLanguage(key);
  };
  const navigationBarItemHandle = (item) => {
    var pattern = /(\w+-?\w+)/,
      str = item.href;
    if (str === "/") {
      navigate("/");
      if (process.env.PROJECT === "wus") {
        setlogos({
          "kron": <img src={logoText} alt="Logo" />,
          "pd": <img src={pdlogo} alt="Logo" />,
          "wus": <img src={wuslogo} alt="Logo" />
        })
        setHeaderBackgroundColor({
          "kron": { backgroundColor: "#010E55" },
          "pd": { backgroundColor: "#010E55" },
          "wus": { backgroundColor: "rgba(255, 55, 78, 0)" },
        })
        setLiTextColor({
          "kron": { color: "#FFFFFF" },
          "pd": { color: "#FFFFFF" },
          "wus": { color: "#000000" }
        })
      }
      return
    }
    const href = pattern.exec(str)[0];
    navigate("/" + href);
    if (process.env.PROJECT === "wus") {
      setlogos({
        "kron": <img src={logoText} alt="Logo" />,
        "pd": <img src={pdlogo} alt="Logo" />,
        "wus": <img src={wuslogoWhite} alt="Logo" />
      })
      setHeaderBackgroundColor({
        "kron": { backgroundColor: "#010E55" },
        "pd": { backgroundColor: "#010E55" },
        "wus": { backgroundColor: "rgba(255, 55, 78, 1)" },
      })
      setLiTextColor({
        "kron": { color: "#FFFFFF" },
        "pd": { color: "#FFFFFF" },
        "wus": { color: "#FFFFFF" }
      })
    }
  };

  const navigateBarItemAction = (item) => {
    if (location !== "/") {
      navigate("/");
    }
  };
  const locations = useLocation();
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShow(false)
    } else {
      setShow(true)
    }
  }
  const lan = {
    "zh-CN": "zhCN",
    "zh-TW": "zhTW"
  }

  const decorateLanguage = (language) => {
    var reg1 = new RegExp("-", "g"); // 加'g'，删除字符串里所有的"a"
    return language.replace(reg1, "");
  }

  useEffect(() => {
    if (locations.pathname != "/") {
      setlogos({
        "kron": <img src={logoText} alt="Logo" />,
        "pd": <img src={pdlogo} alt="Logo" />,
        "wus": <img src={wuslogoWhite} alt="Logo" />
      })

      setHeaderBackgroundColor({
        "kron": { backgroundColor: "#010E55" },
        "pd": { backgroundColor: "#010E55" },
        "wus": { backgroundColor: "rgba(255, 55, 78, 1)" },
      })

      setLiTextColor({
        "kron": { color: "#FFFFFF" },
        "pd": { color: "#FFFFFF" },
        "wus": { color: "#FFFFFF" }
      })
    }
    document.addEventListener('mousedown', (e) => handleClick(e), false)
    return () => {
      document.removeEventListener('mousedown', (e) => handleClick(e), false);
    };
  }, [userInfo]);

  const Menu = () => {
    return (
      <CSSTransition
        in={showMenu}
        key={showMenu}
        timeout={500}
        classNames="menu"
        unmountOnExit
        appear={showMenu}
        onExit={(el) => console.log(t("start_exit"))}
        onExiting={(el) => console.log(t("exiting"))}
        onExited={(el) => console.log(t("exited"))}
      >
        <DropdownMenu
          groups={[groupFront[process.env.PROJECT], groupBefore]}
          onVision={showMenu}
          setVision={setShowMenu}
          className="wus-dropmenu-view"
          onClickClose={() => {
            setShowMenu(false)
          }}
        ></DropdownMenu>
      </CSSTransition>
    );
  };


  const fontAnchorNode = {
    "wus": <Anchor affix={false} style={{ display: "flex" }}>
      {groupFront[process.env.PROJECT].map((item, key) => (
        <li key={key} style={liTextColor[process.env.PROJECT]}>
          <div onClick={navigationBarItemHandle.bind(this, item)} style={{ cursor: 'pointer' }}>
            {/* <Link title={item.title} href={item.href}></Link> */}
            &nbsp;
            {item.title}{" "}
          </div>
        </li>
      ))}
    </Anchor>,
    "kron": <Anchor affix={false} style={{ display: "flex" }}>
      {groupFront[process.env.PROJECT].map((item, key) => (
        <li key={key}>
          <div onClick={navigateBarItemAction.bind(this, item)}>
            <Link title={item.title} href={item.href}></Link>
          </div>
        </li>
      ))}
    </Anchor>,
    "pd": <Anchor affix={false} style={{ display: "flex" }}>
      {groupFront[process.env.PROJECT].map((item, key) => (
        <li key={key}>
          <div onClick={navigateBarItemAction.bind(this, item)}>
            <Link title={item.title} href={item.href}></Link>
          </div>
        </li>
      ))}
    </Anchor>
  }
  return (
    <header id="header" style={headerBackgroundColor[process.env.PROJECT]}>
      <div className="header-footer header-content">
        <div className="header-logo">
          {
            logos[process.env.PROJECT]
          }
        </div>
        <div className="nav-groups">
          <nav className={navClassName[process.env.PROJECT]}>
            <ul className="nav-group">
              {fontAnchorNode[process.env.PROJECT]}
            </ul>
          </nav>
          <nav className={navClassName[process.env.PROJECT]}>
            <ul className="nav-group" style={{ height: "30px" }}>
              {groupBefore.map((item, key) => (
                <li key={key} style={liTextColor[process.env.PROJECT]}>
                  <div
                    href={item.href}
                    onClick={navigationBarItemHandle.bind(this, item)}
                    style={{ cursor: 'pointer' }}
                  >
                    &nbsp;
                    {item.title}{" "}
                  </div>
                </li>
              ))}
              <Dropdown
                menu={{
                  items,
                  onClick,
                  selectable: true,
                  defaultSelectedKeys: ['zh'],
                }}
              >
                <Space style={liTextColor[process.env.PROJECT]}>
                  {t(decorateLanguage(language))}
                  <CaretDownOutlined style={{ marginRight: "8px" }} />
                </Space>
              </Dropdown>
            </ul>
          </nav>
          {isLogin ? (
            <span
              className="login is-login"
              style={liTextColor[process.env.PROJECT]}
              ref={ref}
            >
              {location.pathname === "/" ? <div></div> : <AvatarDefault style={{ marginRight: "8px" }}></AvatarDefault>}
              {userInfo.name !== undefined ? userInfo.name : t("name_error")}
              <UserMenu onShow={show}></UserMenu>
            </span>
          ) : (
            <span className="login" onClick={showHandler.bind(this)}>
              <a href="#" className="login">
                {location.pathname === "/" ? <div></div> : <AvatarDefault style={{ marginRight: "8px" }}></AvatarDefault>}
                <p> {t("login")} </p>
              </a>
            </span>
          )}
        </div>
        <div className="menu-icon">
          <MenuIcon
            style={menuColor[process.env.PROJECT]}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          ></MenuIcon>

          <Menu></Menu>

        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    userInfo: state.userInfo,
    language: state.language
  };
};

const mapDispatchToProps = (action) => {
  return {
    showHandler: () => {
      action({
        type: "SHOWMODAL",
        modalType: "LOGIN",
      });
    },
    changeLanguage: (lan) => {
      action({
        type: "LANGUAGE",
        language: lan,
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
