import React, { useEffect } from "react";
import Banner from "../../components/banner";
import Introduce from "../../components/introduce";
import Buy from "../../components/buy";
import DownloadApp from "../../components/download_app";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReactPixel from 'react-facebook-pixel';
import sha256 from 'crypto-js/sha256';
import { conversions } from "../../api/api";
// import Adjust from "../../adjust/adjust-latest"
const WiceBody = (props) => {
  const { hideHandler } = props;
  const location = useLocation();

  const getUrlToken = (name, str) => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  };

  const createFbEventId = () => {
    return sha256(Cookies.get('_fbp') + new Date().getTime() + Math.round(Math.random() * 1000)).toString()
  }

  useEffect(() => {
    // pixid 
    const referer = Cookies.get("referer")
    console.log("new referer", referer)
    if (referer === "fb") {
      console.log("Cookies fb")
      ReactPixel.init("873169954523992")
      ReactPixel.pageView()
      const eventId = createFbEventId()
      ReactPixel.track("ViewContent", { eventID: eventId })
      conversions("ViewContent", eventId)
    }

    if (referer == "adjust") {
      ReactPixel.init("344342871757432")
      // const eventId = createFbEventId()
      ReactPixel.pageView()
      // Adjust.initSdk({
      //   appToken: "yiafbkgs696o", // yiafbkgs696o
      //   environment: "production",
      //   logLevel: "info",
      //   attributionCallback: function (e, attribution) {
      //     console.log('Adid:' + attribution.adid);
      //   }
      // })
      // const id = setInterval(() => {
      //   if (Adjust.getWebUUID() != undefined) {
      //     conversionsAdjust('glzkwc', Adjust.getWebUUID())
      //     conversionsAdjust('x6nqj5', Adjust.getWebUUID())
      //     clearInterval(id)
      //   }
      // }, 1000);
      // Adjust.trackEvent({
      //   eventToken: 'glzkwc',
      //   deduplicationId: eventId
      // })
      // Adjust.trackEvent({
      //   eventToken: 'x6nqj5',
      //   deduplicationId: eventId
      // })

    }


    const session = getUrlToken("session", location.search);
    const name = getUrlToken("name", location.search);
    const userId = getUrlToken("userId", location.search);

    if (
      session !== undefined &&
      name !== undefined &&
      userId !== undefined &&
      session !== null &&
      name !== null &&
      userId !== null
    ) {
      Cookies.set("session", session);
      let userInfo = { name: name, user_id: userId };
      Cookies.set("user-info", JSON.stringify(window.btoa(userInfo)));
      hideHandler(userInfo);
    }

    // let userInfo = Cookies.get("user-info");
    // let info = JSON.parse(window.atob(userInfo));
    // console.log(info)
    // if (userInfo === undefined || userInfo === null) {
    //   info = {};
    // } else {
    //   info = 
    // }
  }, []);

  const bodyContent = {
    "wus": <Banner></Banner>,
    "pd": <div>
      <Banner></Banner>
      {/* <Introduce></Introduce> */}
      <Buy></Buy>
      <DownloadApp></DownloadApp>
    </div>,
    "kron": <div>
      <Banner></Banner>
      {/* <Introduce></Introduce> */}
      <Buy></Buy>
      <DownloadApp></DownloadApp>
    </div>,
  }

  return (
    bodyContent[process.env.PROJECT]
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideHandler: (userInfo) => {
      dispatch({
        type: "HIDEMODAL",
        state: { userInfo },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(WiceBody);
