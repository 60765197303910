import React, { useState, useEffect } from "react";
import "../../asset/css/branch.scss";
import { useTranslation } from "react-i18next"

const UserTerms = (props) => {
  const [dataSource, setDataSource] = useState({});
  const { t } = useTranslation()
  useEffect(() => {
    // userterms().then((data) => {
    //   setDataSource(data);
    // });
  }, []);
  return (
    <div className="user-terms-body">
      <div className="content-card">
        <div>
          <h1>{t('userterm')}</h1>
          <p>{t('userterm_content.p1', { name: t('app') })}
            <br />
            {t('userterm_content.p12')}</p>
        </div>
        <div>
          <h2>{t('userterm_content.h2')}</h2>
          <p>{t('userterm_content.p2', { name: t('app') })}</p>
        </div>
        <div>
          <h2>{t('userterm_content.h3')}</h2>
          <p>
            <p dangerouslySetInnerHTML={{ __html: t('userterm_content.p3', { name: t('app') }) }}></p>
          </p>
        </div>
        <div>
          <h2>{t('userterm_content.h4')}</h2>
          <p dangerouslySetInnerHTML={{ __html: t('userterm_content.p4', { name: t('app') }) }}></p>
        </div>
        <div>
          <h2>{t('userterm_content.h5')}</h2>
          <p>{t('userterm_content.p5', { name: t('app') })}</p>
        </div>
        <div>
          <h2>{t('userterm_content.h6')}</h2>
          <p>{t('userterm_content.p6', { name: t('app') })}</p>
        </div>
      </div>
    </div >
  );
};

export default UserTerms;
