import { MacOSIcon, TvIcon, HarmonyOSIcon, DownloadBtn } from "./svgs";
import { AppleFilled, WindowsFilled, AndroidFilled } from "@ant-design/icons";
import { useEffect, useState } from "react"
import { useUA } from 'use-ua-parser-js';
import { conversions, getDownloadLinkUrl } from "../api/api";
import { connect } from "react-redux";
import { message, Modal, Button, Spin } from "antd";
import ReactPixel from "react-facebook-pixel"
import Cookies from "js-cookie";
import DownloadModal from "./download_modal";
import copy from 'copy-text-to-clipboard';
import { saveS2sData, adjustTrackToken, conversionsAdjust } from "../api/api";
import sha256 from 'crypto-js/sha256';
// import Adjust from "../adjust/adjust-latest"
import { useTranslation } from 'react-i18next';
const DownIconStyle = {
    "kron": "other-download-list",
    "pd": "other-download-list",
    "wus": "wus-other-download-list"
}

const otherPlatformTextStyle = {
    "kron": {
        color: "white",
        fill: "white"
    },
    "pd": {
        color: "white"
    },
    "wus": {
        color: "black",
        fontSize: "16px"
    }
}

const downlaodButtonClassName = {
    "kron": "banner-button",
    "pd": "banner-button",
    "wus": "wus-banner-button"
}


const DownloadIcons = {
    "Mac OS": {
        icon: <MacOSIcon></MacOSIcon >,
        platform: "mac"
    },
    "Android": {
        icon: <AndroidFilled ></AndroidFilled>,
        platform: "android"
    },
    "iOS": {
        icon: <AppleFilled ></AppleFilled>,
        platform: "ios"
    },
    "Windows": {
        icon: <WindowsFilled ></WindowsFilled>,
        platform: "windows"
    },
    "TV": {
        icon: <TvIcon ></TvIcon>,
        platform: "androidTV"
    },
    "HarmonyOS": {
        icon: <HarmonyOSIcon ></HarmonyOSIcon>,
        platform: "android"
    }
}



const DownloadButton = (props) => {
    const { setDownloadLinkHandle } = props
    const [links, setLinks] = useState([]);
    const UADetails = useUA();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonLock, setButtonLock] = useState(false)
    const [tipsModal, setTipsModal] = useState(false);
    const { t } = useTranslation()

    const LoadingModal = (props) => {
        const { open } = props
        return (
            <div className="download-modal" style={open ? { display: "flex" } : { display: "none" }}>
                <div className="loading-modal-content">
                    <Spin tip={t("get_download_link")}></Spin>
                </div>
            </div>
        )
    }

    const createFbEventId = () => {
        return sha256(Cookies.get('_fbp') + new Date().getTime() + Math.round(Math.random() * 1000)).toString()
    }

    useEffect(async () => {
        //获取下载链接
        let result = await getDownloadLinkUrl()
        setDownloadLinkHandle(result)
        setLinks(result)
        return () => { };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sendDownloadEvent = (platform) => {
        const referer = Cookies.get("referer")
        if (referer === "fb") {
            var eventId = createFbEventId()
            const s2sBaseData = {
                "fbc": Cookies.get('_fbc') == undefined ? "" : Cookies.get('_fbc'),// fb种的cookie, 点击了广告才存在
                "fbp": Cookies.get('_fbp') == undefined ? "" : Cookies.get('_fbp'),// fb种的cookie, 接了像素就有的唯一id
                "client_user_agent": navigator.userAgent,
                "client_ip_address": '', // *** 服务端填充此值
                "event_source_url": window.location.href,
                "event_id": '',//  md5(fbp+时间戳+随机数)
                "event_name": '',// 默认与track事件保持一致
                "event_time": 0,// *** 服务端需要更新此值
                "external_id": '',// 购买事件才传递，默认为fbp值；服务端如果有了用户唯一uid，可以更新此值
                "currency": '',// 购买事件才传递，默认为 CNY人民币
                "value": 0,// 购买事件才传递
                "fb_login_id": '',// 不接fb登录, 留空
                "em": '',// 不接fb登录, 留空
            };

            let sign = ""
            const keys = Object.keys(s2sBaseData).sort()
            Object.values(keys).forEach((key) => {
                sign = sign + key + "=" + s2sBaseData[key] + "&"
            })
            sign = sign + "key=x0drfi48zqymout1"
            console.log(sign)
            s2sBaseData["signature"] = sha256(sign).toString()
            copy(JSON.stringify(s2sBaseData))
            saveS2sData(s2sBaseData)
            ReactPixel.track("DownloadApp", {
                content_name: 'Download ' + DownloadIcons[UADetails.os.name].platform + 'APP',
                eventID: eventId
            })
            conversions("DownloadApp", eventId)
            ReactPixel.track("StartTrial", { eventID: eventId })
            conversions("StartTrial", eventId)
        }
        if (referer === "adjust" && platform === "android" || platform === "androidTV") {
            var eventId = createFbEventId()
            setTipsModal(true)
            var modaltime = setInterval(() => {
                setTipsModal(false)
                clearInterval(modaltime)
            }, 3500);
            const s2sBaseData = {
                "fbc": Cookies.get('_fbc') == undefined ? "" : Cookies.get('_fbc'),// fb种的cookie, 点击了广告才存在
                "fbp": Cookies.get('_fbp') == undefined ? "" : Cookies.get('_fbp'),// fb种的cookie, 接了像素就有的唯一id
                "client_user_agent": navigator.userAgent,
                "client_ip_address": '', // *** 服务端填充此值
                "event_source_url": window.location.href,
                "event_id": eventId,//  md5(fbp+时间戳+随机数)
                "event_name": '',// 默认与track事件保持一致
                "event_time": 0,// *** 服务端需要更新此值
                "external_id": '',// 购买事件才传递，默认为fbp值；服务端如果有了用户唯一uid，可以更新此值
                "currency": '',// 购买事件才传递，默认为 CNY人民币
                "value": 0,// 购买事件才传递
                "fb_login_id": '',// 不接fb登录, 留空
                "em": '',// 不接fb登录, 留空
            };

            let sign = ""
            const keys = Object.keys(s2sBaseData).sort()
            Object.values(keys).forEach((key) => {
                sign = sign + key + "=" + s2sBaseData[key] + "&"
            })
            sign = sign + "key=x0drfi48zqymout1"

            s2sBaseData["signature"] = sha256(sign).toString()
            copy(JSON.stringify(s2sBaseData))
            saveS2sData(s2sBaseData)
            console.log("[event id]" + eventId)
            // Adjust.trackEvent({
            //     eventToken: "ro72vy",
            //     deduplicationId: eventId
            // })

            conversionsAdjust(eventId, Cookies.get('_fbp') == undefined ? "" : Cookies.get('_fbp'), "DownloadApp")
            return adjustTrackToken()
        }
        return null
    }
    const downloadButtonHandle = () => {
        if (buttonLock == true) {
            return
        } else {
            setButtonLock(true)
            const timeId = setTimeout(() => {
                setButtonLock(false)
                clearTimeout(timeId)
            }, 2000);
        }
        let path = ""
        links.forEach((value, index, array) => {
            let objc = DownloadIcons[UADetails.os.name]
            if (objc === undefined) {
                let anchorName = "#download_app".replace("#", "");
                let anchorElement = document.getElementById(anchorName);
                if (anchorElement) {
                    anchorElement.scrollIntoView();
                }
                return
            }
            if (DownloadIcons[UADetails.os.name].platform === value.platform) {
                path = value.download_path
                return
            }
        })

        if (path !== "") {
            if (DownloadIcons[UADetails.os.name].platform == "windows") {
                showModal()
                return
            }
            const event = sendDownloadEvent(DownloadIcons[UADetails.os.name].platform)
            if (event !== null && event !== undefined) {
                const url = event + "&redirect=" + encodeURIComponent(path)
                console.log("[download url] " + url)
                const link = document.createElement('a');
                link.href = url
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                const link = document.createElement('a');
                link.href = path
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        } else {
            message.info(t("download_tips"))
        }
    }

    const otherDownloadButtonHandle = (platform) => {
        if (buttonLock == true) {
            return
        } else {
            setButtonLock(true)
            const timeId = setTimeout(() => {
                setButtonLock(false)
                clearTimeout(timeId)
            }, 1500);
        }
        let path = ""
        links.forEach((value, index, array) => {
            if (platform === value.platform) {
                path = value.download_path
                return
            }
        })
        if (path !== "") {
            if (platform === "windows") {
                showModal()
                return
            }
            var url = sendDownloadEvent(platform)
            if (url !== null && url !== undefined) {
                url = url + "&redirect=" + encodeURIComponent(path)
                console.log("[download url] " + url)
                const link = document.createElement('a');
                link.href = url
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                const link = document.createElement('a');
                link.href = path
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            message.info(t("download_tips"))
        }
    }

    const windowsZipDownload = () => {
        links.forEach(element => {
            if (element.platform == "windows") {
                const link = document.createElement('a');
                console.log(element.download_path.replace("setup.exe", "portable.zip"))
                link.href = element.download_path.replace("setup.exe", "portable.zip");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsModalOpen(false)
            }
        });
    }

    const windowsSetupDownload = () => {
        let path = links.windows
        links.forEach(element => {
            if (element.platform == "windows") {
                const link = document.createElement('a');
                link.href = element.download_path
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setIsModalOpen(false)
            }
        });
    }

    return (
        <div>
            <div className={downlaodButtonClassName[process.env.PROJECT]} onClick={downloadButtonHandle}>
                {DownloadIcons[UADetails.os.name] === undefined ? DownloadIcons["Windows"].icon : DownloadIcons[UADetails.os.name].icon}
                <div className="banner-title">{DownloadIcons[UADetails.os.name] === undefined ? "Windows" : UADetails.os.name} {t("download")}</div>
            </div>
            <div className="banner-button-other">
                <div style={otherPlatformTextStyle[process.env.PROJECT]}>{t("other_platform")}</div>
                {Object.entries(DownloadIcons).map((key, index) => {
                    if (key[0] != UADetails.os.name) {
                        return (
                            <div className={DownIconStyle[process.env.PROJECT]} key={index} onClick={(event) => {
                                otherDownloadButtonHandle(key[1].platform)
                            }}>
                                {key[1].icon}
                            </div>
                        )
                    }
                })}
            </div>
            <DownloadModal
                closeClickHanlde={handleCancel}
                open={isModalOpen}>
                <p>{t("select_install_p")}</p>
                <div className="windows-download-btn-body">
                    <Button className="windows-download-button" style={{ backgroundColor: "#FE364D" }} onClick={windowsSetupDownload}><DownloadBtn className="download-icon" />安装包</Button>
                    <Button className="windows-download-button" style={{ backgroundColor: "#1FBF4A" }} onClick={windowsZipDownload}><DownloadBtn className="download-icon" />绿色包</Button>
                </div>
            </DownloadModal>
            <LoadingModal open={tipsModal}></LoadingModal>
        </div >
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDownloadLinkHandle: (downloadLinks) => {
            dispatch({
                type: "DOWNLOADLINKS",
                state: { downloadLinks },
            });
        },
    };
};
export default connect(null, mapDispatchToProps)(DownloadButton);